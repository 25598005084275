import React, { useState, useRef } from 'react'
import { _ } from 'lodash'
import MetaTags from 'react-meta-tags'

import Breadcrumb from '../common/breadcrumb'

import { Link } from 'react-router-dom'

import moment from 'moment'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  ModalBody,
  CardHeader,
  Modal,
  ModalFooter,
} from 'reactstrap'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'

import { gql, useMutation, useQuery } from '@apollo/client'

const CHANGE_GROUP = gql`
  mutation changeuseralways($id: Int!, $always: String) {
    changeuseralways(id: $id, always: $always) {
      id
    }
  }
`

const QUERY_PROUDCTSBYIDFIRSTMUTATION2 = gql`
  query productpage {
    productpage(id: 1) {
      Product(orderBy: { id: desc }) {
        createdAt
        id
        title
        description
        type
        brand
        category
        price
        newproduct
        sale
        stock
        discount
        variants
        images
        always
      }
    }
  }
`
const New_page = (props) => {
  return <div>dddd</div>
}

export default New_page
