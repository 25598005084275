import React, { useState, useRef, useEffect } from 'react'
import { _ } from 'lodash'
import MetaTags from 'react-meta-tags'

import Breadcrumb from '../common/breadcrumb'

import { Link } from 'react-router-dom'
import * as XLSX from 'xlsx'

import moment from 'moment'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  ModalBody,
  CardHeader,
  Modal,
  ModalFooter,
  Spinner,
} from 'reactstrap'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'

import { gql, useMutation, useQuery } from '@apollo/client'

const CHANGE_GROUP = gql`
  mutation changeuseralways($id: Int!, $always: String) {
    changeuseralways(id: $id, always: $always) {
      id
    }
  }
`

const CHANGE_DISPLAY = gql`
  mutation changeuserdisplay($id: Int!, $display: String) {
    changeuserdisplay(id: $id, display: $display) {
      id
    }
  }
`

const QUERY_PROUDCTSBYIDFIRSTMUTATION2 = gql`
  query productpage {
    productpage(id: 1) {
      Product(orderBy: { id: desc }) {
        createdAt
        id
        title
        description
        type
        brand
        category
        price
        newproduct
        sale
        stock
        discount
        variants
        images
        always
        display
      }
    }
  }
`
const Product_list = (props) => {
  var node = useRef()
  const [opendelete, setOpendelete] = useState(false)
  const onCloseModaldelete = () => {
    setOpendelete(false)
  }
  const { SearchBar } = Search
  const [selectedIds, setSelectedIds] = useState([])
  const [formState, setFormState] = useState({
    orderdata: '',
    product: '',
    id: 0,
    productImages: '',
    subtitle: '',
    descriptionHTMLContent: '',
    title: '',
    description: '',
    type: '',
    brand: '',
    category: '',
    price: 0,
    newproduct: '',
    sale: '',
    stock: '',
    discount: 0,
    variants: '',
    images: '',
    userId: 0,
    productpageId: 0,
    businesscard: '',
    userinfo: '',
    displayStatus: null,
  })
  var { data: productdata } = useQuery(QUERY_PROUDCTSBYIDFIRSTMUTATION2, {
    variables: {
      id: 1,
    },
    onCompleted: (productdata) => {
      setFormState({
        ...formState,
        product: productdata.productpage.Product,
      })
    },
  })

  useEffect(() => {
    console.log('selectedIds', selectedIds)
  }, [selectedIds])

  const contactListColumns = [
    {
      text: '상품이미지',
      dataField: 'images',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          <img style={{ width: '50PX' }} src={item.images} />
        </div>
      ),
    },
    {
      text: '등록 날짜',
      dataField: 'date',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div className="d-flex gap-3">
          {moment(new Date(item.createdAt)).format('YYYY-MM-DD-A hh:mm')}
        </div>
      ),
    },
    {
      text: '상품 ID',
      dataField: 'id',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
    },
    {
      text: '상품명',
      dataField: 'title',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => <div> {item.title}</div>,
    },
    {
      text: '상시구매',
      dataField: 'always',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          {item.always == 'no' ? (
            <Button
              color="primary"
              type="button"
              onClick={() => onClickDelete(item)}
            >
              비활성
            </Button>
          ) : (
            <Button
              color="secondary"
              type="button"
              onClick={() => onClickDelete(item)}
            >
              활성
            </Button>
          )}
        </div>
      ),
    },
    {
      text: '화면노출',
      dataField: 'display',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          {item.display == 'yes' ? (
            <Button
              color="secondary"
              type="button"
              onClick={() => onClickDelete(item, item.display)}
            >
              활성
            </Button>
          ) : (
            <Button
              color="primary"
              type="button"
              onClick={() => onClickDelete(item, item.display)}
            >
              비활성
            </Button>
          )}
        </div>
      ),
    },
    {
      text: '수정하기',
      dataField: 'shipping_amount',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          <Link to={`/pages/edit-page/${item.id}`}>수정하기</Link>
        </div>
      ),
    },
  ]

  const onClickDelete = (user, args) => {
    if (args) {
      setFormState({
        ...formState,
        userinfo: user,
        displayStatus: args,
      })
      setOpendelete(true)
    } else {
      setFormState({
        ...formState,
        userinfo: user,
        displayStatus: null,
      })
      setOpendelete(true)
    }
  }

  const [changebyidDisplay, { data2235, error2235 }] = useMutation(
    CHANGE_DISPLAY,
    {
      onCompleted: (data2235) => {
        window.alert('변경 완료')
        window.location.reload()
      },
      onError: (error2235) => {
        window.alert('에러 발생')
      },
    },
  )

  const [changebyid, { data223, error223 }] = useMutation(CHANGE_GROUP, {
    onCompleted: (data223) => {
      window.alert('변경 완료')
      window.location.reload()
    },
    onError: (error223) => {
      window.alert('에러 발생')
    },
  })

  const startdeletebyidmutation = (args) => {
    if (args) {
      if (formState.userinfo.display == 'no') {
        changebyidDisplay({
          variables: {
            id: Number(formState.userinfo.id),
            display: String('yes'),
          },
        })
      } else {
        changebyidDisplay({
          variables: {
            id: Number(formState.userinfo.id),
            display: String('no'),
          },
        })
      }
    } else {
      if (formState.userinfo.always == 'no') {
        changebyid({
          variables: {
            id: Number(formState.userinfo.id),
            always: String('yes'),
          },
        })
      } else {
        changebyid({
          variables: {
            id: Number(formState.userinfo.id),
            always: String('no'),
          },
        })
      }
    }
  }

  // const handleRefundStatusChange = () => {
  //   if (selectedIds.length > 0) {
  //     Download_excel_selected({
  //       variables: {
  //         item: JSON.stringify(selectedIds),
  //       },
  //     })
  //   } else {
  //     window.alert('항목을 선택해주세요')
  //   }
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>관리자페이지 </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="제품 목록" parent="Pages" />
          <Row>
            <Col xl="12 xl-100">
              <Card>
                <CardHeader>
                  <h5>제품 목록</h5>
                </CardHeader>
                <CardBody>
                  <div
                    className="table-responsive "
                    style={{
                      maxWidth: '100%',
                      overflowX: 'scroll',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {productdata?.productpage?.Product && (
                      <PaginationProvider
                        pagination={paginationFactory({
                          sizePerPage: 500,
                          totalSize: productdata?.productpage?.Product.length, // replace later with size(users),
                          custom: true,
                        })}
                        keyField={'id'}
                        columns={contactListColumns}
                        data={productdata?.productpage?.Product}
                      >
                        {({ paginationProps, paginationTableProps }) => {
                          return (
                            <ToolkitProvider
                              keyField={'id'}
                              data={productdata?.productpage?.Product}
                              columns={contactListColumns}
                              bootstrap4
                              search
                            >
                              {(toolkitProps) => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col sm="4">
                                      <div className="search-box ms-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                          />

                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col sm="8"></Col>
                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={'id'}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                          defaultSorted={[
                                            {
                                              dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
                                              order: 'desc', // desc or asc
                                            },
                                          ]}
                                          classes={
                                            'table align-middle table-bordered table-hover text-centered'
                                          }
                                          bordered={false}
                                          striped={false}
                                          responsive
                                          ref={node}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="pagination pagination-rounded justify-content-end mb-2">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )
                        }}
                      </PaginationProvider>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {formState && (
          <>
            <Modal
              isOpen={opendelete}
              toggle={onCloseModaldelete}
              style={{ overlay: { opacity: 0.1 } }}
            >
              <ModalBody>
                <Label htmlFor="recipient-name" className="col-form-label">
                  {formState.displayStatus !== null ? (
                    formState.displayStatus === 'no' ? (
                      <>{formState.userinfo.title}을(를) 노출하시겠습니까?</>
                    ) : (
                      <>
                        {formState.userinfo.title}을(를) 노출하지
                        않으시겠습니까?
                      </>
                    )
                  ) : formState.userinfo.always === 'no' ? (
                    <>{formState.userinfo.title}을(를) 활성화시키시겠습니까?</>
                  ) : (
                    <>
                      {formState.userinfo.title}을(를) 비활성화시키시겠습니까?
                    </>
                  )}
                </Label>

                {/* <Input type="text"  readOnly  className="form-control" /> */}
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="primary"
                  onClick={() =>
                    startdeletebyidmutation(formState.displayStatus)
                  }
                >
                  변경
                </Button>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => onCloseModaldelete('VaryingMdo')}
                >
                  닫기
                </Button>
              </ModalFooter>
            </Modal>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default Product_list
