import React, { Fragment, useState, useEffect, useRef } from 'react'
import Breadcrumb from './common/breadcrumb'
import { useQuery, useMutation } from '@apollo/client'
import { gql } from '@apollo/client'
import { Link } from 'react-router-dom'

import moment from 'moment'

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'

const GET_ORDER = gql`
  query paidorders {
    paidorders {
      id
      createdAt
      product_main_image
      name
      keepingamount
      wholeamount
      multiorder
      shipping_amount
      created_at
      updated_at
      item_price
      paidstatus
      shippingStatus
      userId
      User {
        name
        id
        phonenumber
      }
    }
  }
`

const ORDER_MUTATION = gql`
  mutation updateOrder($id: Int!, $paidstatus: String!) {
    updateOrder(id: $id, paidstatus: $paidstatus) {
      id
    }
  }
`
const SHIPPING_MUTATION = gql`
  mutation updateShipping($id: Int!, $shippingStatus: String!) {
    updateShipping(id: $id, shippingStatus: $shippingStatus) {
      id
    }
  }
`

const PAID_ORDER_MUTATION = gql`
  mutation createPaidorderlist(
    $userId: Int!
    $productid: Int!
    $title: String!
    $category: String!
    $price: String!
    $discount: String!
    $images: String!
    $wholeamount: String!
    $keepingamount: String!
    $shipping_amount: String!
    $updated_at: String!
    $orderstatus: String!
  ) {
    createPaidorderlist(
      userId: $userId
      productid: $productid
      title: $title
      category: $category
      price: $price
      discount: $discount
      images: $images
      wholeamount: $wholeamount
      keepingamount: $keepingamount
      shipping_amount: $shipping_amount
      updated_at: $updated_at
      orderstatus: $orderstatus
    ) {
      id
    }
  }
`

const Paidorderlist = () => {
  var node = useRef()

  const [open, setOpen] = useState(false)

  const [orderdatadetail, setorderdatadetail] = useState()
  const [demendata, setdemendata] = useState()
  const [formState, setFormState] = useState({
    orderdata: '',
    users: '',
    shipstatus: null,
    totalQty: null,
  })
  const { SearchBar } = Search
  var { loading, data } = useQuery(GET_ORDER, {
    onCompleted: (data) => {
      setFormState({
        ...formState,
        orderdata: data.paidorders,
      })
    },
    onError: (error) => {
      return window.alert('오류 발생')
    },
  })
  const onOpenModal = () => {
    setOpen(true)
  }

  const onCloseModal = () => {
    setOpen(false)
  }

  const finalOrder = async () => {
    const now = new Date()
    const formattedDate = moment(now).format('YY년MM월DD일 HH:mm')
    let orderDate = moment(new Date(demendata.createdAt)).format('YY년MM월DD일')
    // 전화번호에서 숫자만 추출
    const rawNumber = demendata.User.phonenumber.replace(/[^0-9]/g, '')
    const phoneNumber = rawNumber.length === 11 ? rawNumber : 'Invalid number'

    // 주문자 정보 및 상품 정보
    const orderName = demendata?.User?.name + '님'
    const orderProductQty = formState.totalQty

    const message = `
  ${orderName} 안녕하세요.
  ${orderDate} 에 주문하신
  상품 ${orderProductQty} 개가
  ${formattedDate}에 발송이 시작되었습니다.
  계속 발전하는 요요모 되겠습니다.
  감사합니다.
`

    const aJson = {
      message_type: 'at',
      phn: phoneNumber,
      profile: '155c262993a3736eac86c0306704f7dd18321b63',
      reserveDt: '00000000000000',
      msg: message,
      tmplId: 'biz01',
    }
    try {
      const response = await fetch(
        'https://alimtalk-api.bizmsg.kr/v2/sender/send',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
            userid: 'yoyomo',
          },
          body: JSON.stringify([aJson]),
        },
      )
      if (response) {
        return window.location.reload()
      }
      // 추후 response 처리 로직을 여기에 추가
    } catch (error) {
      console.error('Error while sending message:', error)
    }
  }

  const handledemend = (arg, ship) => {
    if (ship) {
      setFormState({
        ...formState,
        shipstatus: 'yes',
      })

      setdemendata(arg)
      if (arg.multiorder.length > 2) {
        setorderdatadetail(JSON.parse(arg.multiorder))
      } else {
        setorderdatadetail(arg)
      }

      setOpen(true)
    } else {
      setdemendata(arg)
      if (arg.multiorder.length > 2) {
        setorderdatadetail(JSON.parse(arg.multiorder))
      } else {
        setorderdatadetail(arg)
      }

      setOpen(true)
    }
  }

  console.log('demendata', demendata)
  const [ordercreated, { data: updatedata, error: errordata }] = useMutation(
    ORDER_MUTATION,
    {
      onCompleted: () => {
        window.alert('업데이트 완료')
        return window.location.reload()
      },

      onError: (errordata) => {
        return window.alert('오류 발생')
      },
    },
  )

  const [shippingcreated, { data: updatedata1, error: errordata1 }] =
    useMutation(SHIPPING_MUTATION, {
      onCompleted: async () => {
        window.alert('업데이트 완료')
        await finalOrder()
      },

      onError: (errordata1) => {
        return window.alert('오류 발생')
      },
    })

  console.log('fffff', formState)
  useEffect(() => {
    if (demendata) {
      if (demendata?.multiorder !== 'no') {
        console.log('finalData', JSON.parse(demendata?.multiorder))

        const totalQuantity = JSON.parse(demendata?.multiorder).reduce(
          (acc, product) => acc + Number(product.qty),
          0,
        )

        // formState의 상태 업데이트
        setFormState((prevState) => ({
          ...prevState,
          totalQty: totalQuantity,
        }))
      } else {
        setFormState((prevState) => ({
          ...prevState,
          totalQty: demendata?.shipping_amount,
        }))
      }
    }
  }, [demendata])
  const startupdatemutationfordemend = (arg) => {
    if (demendata !== null) {
      if (arg) {
        if (demendata.shippingStatus == '배송준비') {
          shippingcreated({
            variables: {
              id: Number(demendata.id),
              shippingStatus: String('발송처리'),
            },
          })
        } else {
          shippingcreated({
            variables: {
              id: Number(demendata.id),
              shippingStatus: String('배송준비'),
            },
          })
        }

        //배송상태 변경
      } else {
        if (demendata.paidstatus == 'no') {
          ordercreated({
            variables: {
              id: Number(demendata.id),
              paidstatus: String('yes'),
            },
          })
        } else {
          ordercreated({
            variables: {
              id: Number(demendata.id),
              paidstatus: String('no'),
            },
          })
        }
      }
    }
  }

  const contactListColumns = [
    {
      text: '주문 날짜',
      dataField: 'date',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div className="d-flex gap-3">
          {moment(new Date(item.createdAt)).format('YYYY-MM-DD-A hh:mm')}
        </div>
      ),
    },
    {
      text: '주문 ID',
      dataField: 'id',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
    },
    {
      text: '성함',
      dataField: 'name',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => <div> {item.User.name}</div>,
    },

    {
      text: '입금상태',
      dataField: 'shippingStatus',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          {item.paidstatus == 'no' ? (
            <button
              className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
              onClick={() => handledemend(item)}
            >
              {' '}
              변경
            </button>
          ) : (
            <span className="btn btn-success btn-sm btn-delete mb-0 b-r-4">
              {' '}
              입금완료
            </span>
          )}
        </div>
      ),
    },
    {
      text: '배송처리',
      dataField: 'shi',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          {item.shippingStatus == '배송준비' ? (
            <button
              className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
              onClick={() => handledemend(item, '배송')}
            >
              {' '}
              배송준비
            </button>
          ) : (
            <span className="btn btn-success btn-sm btn-delete mb-0 b-r-4">
              {' '}
              발송처리
            </span>
          )}
        </div>
      ),
    },

    {
      text: '주문 상세',
      dataField: 'shipping_amount',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          <Link to={`/sales/orders/${item.id}`}>상세보기</Link>
        </div>
      ),
    },
  ]

  return (
    <Fragment>
      {formState && (
        <>
          <Modal
            isOpen={open}
            toggle={onCloseModal}
            style={{ overlay: { opacity: 0.1 } }}
          >
            <ModalHeader toggle={onCloseModal}>
              <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                {formState.shipstatus ? '배송상태 변경' : ' 정산확인상태 변경'}
              </h5>
            </ModalHeader>
            <ModalBody>
              <Label htmlFor="recipient-name" className="col-form-label">
                현재 상태 :{' '}
                {formState.shipstatus
                  ? demendata?.shippingStatus
                  : demendata?.paidstatus}
              </Label>
              <h4>
                {formState.shipstatus ? (
                  <>
                    배송상태를{' '}
                    {demendata?.shippingStatus === '배송준비'
                      ? '발송처리'
                      : '배송준비'}
                    로 변경하시겠습니까?{' '}
                  </>
                ) : (
                  <>
                    입금확인 상태를{' '}
                    {demendata?.paidstatus === 'no' ? 'yes' : 'no'}로
                    변경하시겠습니까?{' '}
                  </>
                )}
              </h4>
              {/* <Input type="text"  readOnly  className="form-control" /> */}
            </ModalBody>
            <ModalFooter>
              {formState.shipstatus ? (
                <>
                  <Button
                    type="button"
                    color="primary"
                    onClick={() =>
                      startupdatemutationfordemend(demendata?.shippingStatus)
                    }
                  >
                    변경
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => startupdatemutationfordemend()}
                  >
                    변경
                  </Button>
                </>
              )}

              <Button
                type="button"
                color="secondary"
                onClick={() => onCloseModal('VaryingMdo')}
              >
                닫기
              </Button>
            </ModalFooter>
          </Modal>
          <Breadcrumb title="주문리스트" parent="Dashboard" />
          <Container fluid={true}>
            <Row>
              <Col xl="12 xl-100">
                <Card>
                  <CardHeader>
                    <h5>주문 목록(입금확인 완료)</h5>
                  </CardHeader>
                  <CardBody>
                    <div
                      className="table-responsive "
                      style={{
                        maxWidth: '100%',
                        overflowX: 'scroll',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {formState.orderdata?.length > 0 && (
                        <PaginationProvider
                          pagination={paginationFactory({
                            sizePerPage: 500,
                            totalSize: formState.orderdata.length, // replace later with size(users),
                            custom: true,
                          })}
                          keyField={'id'}
                          columns={contactListColumns}
                          data={formState.orderdata}
                        >
                          {({ paginationProps, paginationTableProps }) => {
                            return (
                              <ToolkitProvider
                                keyField={'id'}
                                data={formState.orderdata}
                                columns={contactListColumns}
                                bootstrap4
                                search
                              >
                                {(toolkitProps) => (
                                  <React.Fragment>
                                    <Row className="mb-2">
                                      <Col sm="4">
                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar
                                              {...toolkitProps.searchProps}
                                            />

                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col sm="8"></Col>
                                    </Row>

                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={'id'}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            defaultSorted={[
                                              {
                                                dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
                                                order: 'desc', // desc or asc
                                              },
                                            ]}
                                            classes={
                                              'table align-middle table-bordered table-hover text-centered'
                                            }
                                            bordered={false}
                                            striped={false}
                                            responsive
                                            ref={node}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )
                          }}
                        </PaginationProvider>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Fragment>
  )
}

// javascript:void(0)

export default Paidorderlist
