import { Home } from 'react-feather'

export const MENUITEMS = [
  // {
  //     path: '/dashboard', title: '대시보드', icon: Home, type: 'link', badgeType: 'primary', active: false
  // },
  {
    path: '/userlist',
    title: '유저현황',
    icon: Home,
    type: 'link',
    badgeType: 'primary',
    active: false,
  },
  {
    path: '/orderlist',
    title: '주문현황',
    icon: Home,
    type: 'link',
    badgeType: 'primary',
    active: false,
  },
  {
    path: '/keeporderlist',
    title: '주문현황(마이창고)',
    icon: Home,
    type: 'link',
    badgeType: 'primary',
    active: false,
  },

  {
    path: '/paidorderlist',
    title: '배송현황',
    icon: Home,
    type: 'link',
    badgeType: 'primary',
    active: false,
  },
  {
    path: '/keepingorderlist',
    title: '배송현황(마이창고)',
    icon: Home,
    type: 'link',
    badgeType: 'primary',
    active: false,
  },
  {
    path: '/pages/create-category',
    title: '상품카테고리 생성',
    icon: Home,
    type: 'link',
    badgeType: 'primary',
    active: false,
  },
  {
    path: '/pages/create-page',
    title: '상품업로드',
    icon: Home,
    type: 'link',
    badgeType: 'primary',
    active: false,
  },
  {
    path: '/pages/product-list',
    title: '상품수정',
    icon: Home,
    type: 'link',
    badgeType: 'primary',
    active: false,
  },

  // {
  //     title: 'Products', icon: Box, type: 'sub', active: false, children: [
  //         {
  //             title: 'Physical', type: 'sub', active: false, children: [
  //                 { path: '/products/physical/category', title: 'Category', type: 'link' },
  //                 { path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
  //                 { path: '/products/physical/product-list', title: 'Product List', type: 'link' },
  //                 { path: '/products/physical/product-detail', title: 'Product Detail', type: 'link' },
  //                 { path: '/products/physical/add-product', title: 'Add Product', type: 'link' },
  //             ]
  //         },
  //         {
  //             title: 'digital', type: 'sub', active: false, children: [
  //                 { path: '/products/digital/digital-category', title: 'Category', type: 'link' },
  //                 { path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
  //                 { path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
  //                 { path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
  //             ]
  //         },
  //     ]
  // },
  // {
  //     title: 'Sales', icon: DollarSign, type: 'sub', active: false, children: [
  //         { path: '/sales/orders', title: 'Orders', type: 'link' },
  //         { path: '/sales/transactions', title: 'Transactions', type: 'link' },
  //     ]
  // },
  // {
  //     title: 'Coupons', icon: Tag, type: 'sub', active: false, children: [
  //         { path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
  //         { path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
  //     ]
  // },
  // {
  //     title: 'Pages', icon: Clipboard , type: 'sub', active: false, children: [
  //         { path: '/pages/list-page', title: 'List Page', type: 'link' },
  //         { path: '/pages/create-page', title: 'Create Page', type: 'link' },
  //     ]
  // },
  // {
  //     title: 'Media', path: '/media', icon: Camera, type: 'link', active: false
  // },
  // {
  //     title: 'Menus', icon: AlignLeft, type: 'sub', active: false, children: [
  //         { path: '/menus/list-menu', title: 'List Menu', type: 'link' },
  //         { path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
  //     ]
  // },
  // {
  //     title: 'Users', icon: UserPlus, type: 'sub', active: false, children: [
  //         { path: '/users/list-user', title: 'User List', type: 'link' },
  //         { path: '/users/create-user', title: 'Create User', type: 'link' },
  //     ]
  // },
  // {
  //     title: 'Vendors', icon: Users, type: 'sub', active: false, children: [
  //         { path: '/vendors/list_vendors', title: 'Vendor List', type: 'link' },
  //         { path: '/vendors/create-vendors', title: 'Create Vendor', type: 'link' },
  //     ]
  // },
  // {
  //     title: 'Localization', icon: Chrome, type: 'sub', children: [
  //         { path: '/localization/transactions', title: 'Translations', type: 'link' },
  //         { path: '/localization/currency-rates', title: 'Currency Rates', type: 'link' },
  //         { path: '/localization/taxes', title: 'Taxes', type: 'link' }
  //     ]
  // },
  // {
  //     title: 'Reports',path:'/reports/report', icon: BarChart, type: 'link', active: false
  // },
  // {
  //     title: 'Settings', icon: Settings, type: 'sub', children: [
  //         { path: '/settings/profile', title: 'Profile', type: 'link' },
  //     ]
  // },
  // {
  //     title: 'Invoice',path:'/invoice', icon: Archive, type: 'link', active: false
  // },
  // {
  //     title: 'Login',path:'/auth/login', icon: LogIn, type: 'link', active: false
  // }
]
