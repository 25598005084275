import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './index.scss'
import App from './components/app'
import PerfectScrollbar from 'react-perfect-scrollbar'

// Components
import Dashboard from './components/dashboard'

//Sales
import Orders from './components/sales/orders'
import Keeporders from './components/sales/keeporders'
import Transactions_sales from './components/sales/transactions-sales'

//Pages
import ListPages from './components/pages/list-page'
import Create_page from './components/pages/create-page'
import Edit_page from './components/pages/edit-page'
import Product_list from './components/pages/product-list'
import Create_category from './components/pages/create-category'

import Login from './components/auth/login'

//요요모
import Userlist from './components/userlist'
import Orderlist from './components/orderlist'
import Keeporderlist from './components/keeporderlist'

import Paidorderlist from './components/paidorderlist'
import Keepingorderlist from './components/keepingorderlist'
import New_page from './components/pages/new-page'

import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloProvider,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

// const httpLink = new HttpLink({ uri: 'http://localhost' })

const httpLink = new HttpLink({
  uri: 'https://www.yoyomoserver.shop/',
})

const authLink = setContext(async (req, { headers }) => {
  const token = localStorage.getItem('token')

  return {
    ...headers,
    headers: {
      Authorization: token ? `Bearer ${token}` : null,
    },
  }
})

const link = authLink.concat(httpLink)
const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
})

const Root = () => {
  return (
    <BrowserRouter basename={'/'}>
      <PerfectScrollbar>
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/auth/login`}
            component={Login}
          />

          <App>
            <Route
              path={`${process.env.PUBLIC_URL}/dashboard`}
              component={Dashboard}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/userlist`}
              component={Userlist}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/paidorderlist`}
              component={Paidorderlist}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/keepingorderlist`}
              component={Keepingorderlist}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/orderlist`}
              component={Orderlist}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/keeporderlist`}
              component={Keeporderlist}
            />

            {/* <Route
              path={`${process.env.PUBLIC_URL}/products/physical/product-list`}
              component={Product_list}
            /> */}

            <Route
              path={`${process.env.PUBLIC_URL}/sales/orders/:id`}
              component={Orders}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/sales/keeporders/:id`}
              component={Keeporders}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/sales/transactions/:id`}
              component={Transactions_sales}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/pages/list-page`}
              component={ListPages}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/pages/create-page`}
              component={Create_page}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/pages/create-category`}
              component={Create_category}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/pages/edit-page/:id`}
              component={Edit_page}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/pages/product-list`}
              component={Product_list}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/pages/new-page`}
              component={New_page}
            />
          </App>
        </Switch>
      </PerfectScrollbar>
    </BrowserRouter>
  )
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <Root />
  </ApolloProvider>,
  document.getElementById('root'),
)
