import React, { Fragment, useState } from 'react'

import { AlignLeft } from 'react-feather'
import { gql, useMutation, useQuery } from '@apollo/client'

const ME_QUERY = gql`
  query me {
    me {
      id
      name
      email
    }
  }
`
const Header = () => {
  const { error, data } = useQuery(ME_QUERY, {
    onCompleted: async (data) => {
 
      if (data.me.id !== 2) {
        await window.alert('접근권한이 없습니다')
		return window.location.href = "/"
		
		
      }
    },
    onError: (error) => {
      return window.alert('접근권한이 없습니다')
    },
  })

  const [sidebar, setSidebar] = useState(true)
  const [rightSidebar, setRightSidebar] = useState(true)
  const [navMenus, setNavMenus] = useState(false)

  const toggle = () => {
    setNavMenus((prevState) => ({
      navMenus: !prevState.navMenus,
    }))
  }

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(false)
      document.querySelector('.page-main-header').classList.add('open')
      document.querySelector('.page-sidebar').classList.add('open')
    } else {
      setSidebar(true)
      document.querySelector('.page-main-header').classList.remove('open')
      document.querySelector('.page-sidebar').classList.remove('open')
    }
  }

  return (
    <Fragment>
      {/* open */}
      <div className="page-main-header ">
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none">
            <div className="logo-wrapper" style={{ margin: 'auto' }}>
              <a href="/">
                <h4 style={{ margin: 'auto' }}>요요모 관리자</h4>
              </a>
            </div>
          </div>
          <div className="mobile-sidebar">
            <div className="media-body text-right switch-sm">
              <label className="switch">
                <a href="#javaScript" onClick={openCloseSidebar}>
                  <AlignLeft />
                </a>
              </label>
            </div>
          </div>
          <div className="nav-right col"></div>
        </div>
      </div>
    </Fragment>
  )
}

export default Header
