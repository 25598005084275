import React, { Fragment, useState, useRef } from 'react'
import Breadcrumb from '../common/breadcrumb'

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Media,
} from 'reactstrap'
import { useParams } from 'react-router-dom'
import { useQuery, useLazyQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import moment from 'moment'
import { Link } from 'react-router-dom'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'

const GET_ORDER = gql`
  query keepdemanddatabyorderid($id: Int!) {
    keepdemanddatabyorderid(id: $id) {
      id
      createdAt
      orderId
      productId
      productName
      productPrice
      productQty
      productKeepQty
      demandproductKeepQty
      messagefromcustomer
      messagefromadmin
      orderaddress
    }
  }
`

const Keeporders = (props) => {
  var node = useRef()
  const { SearchBar } = Search
  const imgStyle = {
    maxHeight: 128,
  }

  const handle = useParams()
  const [open, setOpen] = useState(false)
  const [orderdataid, setorderdataid] = useState()
  const [orderdata, setorderdata] = useState()
  const [orderdatadetail, setorderdatadetail] = useState()
  const [formState, setFormState] = useState({
    id: '',
    users: '',
  })
  const onOpenModal = () => {
    setOpen(true)
  }

  const onCloseModal = () => {
    setOpen(false)
  }


  var { loading, data } = useQuery(GET_ORDER, {
    variables: {
      id: Number(props.match.params.id),
    },
 
    onError: (error) => {
      return window.alert("오류 발생")
    },
  })

  const contactListColumns = [
    {
      text: '이미지',
      dataField: 'images',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div className="d-flex gap-3">
          <a
            target="_blank"
            href={`https://www.yoyomo.co.kr/product-details/` + item.id}
          >
            <div
              style={{
                height: '90px !important',
              }}
            >
              <Media style={imgStyle} src={item.images} alt="" />
            </div>
          </a>
        </div>
      ),
    },
    {
      text: '상품 ID',
      dataField: 'id',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
    },
    {
      text: '상품명',
      dataField: 'title',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => <div> {item.title}</div>,
    },

    {
      text: '키핑 수량',
      dataField: 'qtyforkeep',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => <div> {item.qtyforkeep}개</div>,
    },

    {
      text: '발송요청 수량',
      dataField: 'userId',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>{Number(item.qty) - Number(item.qtyforkeep)}개</div>
      ),
    },
    {
      text: '총 수량',
      dataField: 'qty',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => <div>{item.qty}개</div>,
    },
  ]

  return (
    <Fragment>
      <Breadcrumb title="마이창고 인출요청 상세" parent="Sales" />

      <Container fluid={true}>
        <Row>
          <Col xl="12 xl-100">
            <Card>
              <CardHeader>
                <h5>마이창고 인출요청 상세</h5>
              </CardHeader>
              <CardBody>
                {data && (
                  <>
                    <section className="section-b-space light-layout">
                      <Container>
                        <Row>
                          <Col md="12">
                            <div
                              className="success-text text-left"
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              {/* <i className="fa fa-check-circle" aria-hidden="true"></i> */}

                              <p>
                                주문번호 -{' '}
                                {data?.keepdemanddatabyorderid[0]?.orderId}번{}
                              </p>
                              <p>
                                메세지 -{' '}
                                {data?.keepdemanddatabyorderid[0]
                                  ?.messagefromcustomer == ''
                                  ? '없음'
                                  : data?.keepdemanddatabyorderid[0]
                                      ?.messagefromcustomer}
                              </p>
                              <p>
                                수취인 주소 -{' '}
                                {data?.keepdemanddatabyorderid[0]?.orderaddress}
                              </p>
                              <p>
                                신청일 -{' '}
                                {moment(
                                  new Date(
                                    data?.keepdemanddatabyorderid[0]?.createdAt,
                                  ),
                                ).format('YYYY-MM-DD-A hh:mm')}
                                {}
                              </p>
                            </div>

                            {data?.keepdemanddatabyorderid !== undefined && (
                              <>
                                <div className="checkout-details">
                                  <div className="order-box">
                                    {data !== undefined &&
                                      data?.keepdemanddatabyorderid.map(
                                        (item, index) => (
                                          <>
                                            <div key={index}>
                                              <ul className="qty">
                                                <li>
                                                  <a
                                                    target="_blank"
                                                    href={
                                                      `https://www.yoyomo.co.kr//product-details/` +
                                                      item.productId
                                                    }
                                                  >
                                                    {' '}
                                                    {item.productName}
                                                  </a>
                                                </li>
                                                <br />
                                                <li>
                                                  개당 가격 :{' '}
                                                  {Number(item.productPrice)
                                                    .toFixed(0)
                                                    .toString()
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ',',
                                                    )}
                                                  원
                                                </li>
                                                <br />
                                                <li>
                                                  인출 신청 키핑수량 :{' '}
                                                  {item.demandproductKeepQty}개
                                                </li>
                                              </ul>
                                              <ul className="sub-total">
                                                <li>
                                                  {Number(
                                                    item.demandproductKeepQty,
                                                  ) *
                                                    Number(item.productPrice) >=
                                                  90000 ? (
                                                    <>
                                                      배송비 : 무료(90,000원
                                                      이상 구매 시 무료)
                                                    </>
                                                  ) : (
                                                    <> 배송비: 3,500원</>
                                                  )}
                                                </li>
                                              </ul>
                                              <ul className="sub-total">
                                                <li>
                                                  총 합계 :
                                                  {Number(
                                                    item.demandproductKeepQty,
                                                  ) *
                                                    Number(item.productPrice) >=
                                                  90000 ? (
                                                    <>
                                                      {(
                                                        Number(
                                                          item.demandproductKeepQty,
                                                        ) *
                                                        Number(
                                                          item.productPrice,
                                                        )
                                                      )
                                                        .toFixed(0)
                                                        .toString()
                                                        .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ',',
                                                        )}
                                                      원
                                                    </>
                                                  ) : (
                                                    <>
                                                      {' '}
                                                      {Number(
                                                        Number(
                                                          item.demandproductKeepQty,
                                                        ) *
                                                          Number(
                                                            item.productPrice,
                                                          ) +
                                                          3500,
                                                      )
                                                        .toFixed(0)
                                                        .toString()
                                                        .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ',',
                                                        )}
                                                      원
                                                    </>
                                                  )}
                                                </li>
                                              </ul>
                                            </div>
                                          </>
                                        ),
                                      )}

                                    <ul className="total">
                                      <a
                                        className="btn btn-danger btn-sm btn-delete mt-4 mb-4 b-r-4 "
                                        href="/keeporderlist"
                                      >
                                        키핑 인출목록
                                      </a>
                                    </ul>
                                  </div>
                                </div>
                              </>
                            )}
                          </Col>
                        </Row>
                      </Container>
                    </section>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default Keeporders
