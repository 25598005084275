import React, { Fragment, useState, useEffect, useRef } from 'react'
import Breadcrumb from './common/breadcrumb'
import { useQuery, useMutation } from '@apollo/client'
import { gql } from '@apollo/client'
import { Link } from 'react-router-dom'

import moment from 'moment'

import {
  Card,
  CardBody,
  Form,
  Table,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'

const KeepOrder_Query = gql`
  query keepOrderPaidlist {
    keepOrderPaidlist {
      id
      createdAt
      productId
      orderId
      productName
      productPrice
      productQty
      productKeepQty
      demandproductKeepQty
      messagefromcustomer
      messagefromadmin
      paidstatus
    }
  }
`

const ORDER_MUTATION = gql`
  mutation updatekeepOrder($id: Int!, $paidstatus: String!) {
    updatekeepOrder(id: $id, paidstatus: $paidstatus) {
      id
    }
  }
`

const Keepingorderlist = () => {
  var node = useRef()
  const [open, setOpen] = useState(false)

  const [orderdatadetail, setorderdatadetail] = useState()
  const [demendata, setdemendata] = useState()
  const [formState, setFormState] = useState({
    orderdata: '',
    users: '',
  })
  const { SearchBar } = Search

  const { loading, error, data } = useQuery(KeepOrder_Query, {
   
    onError: (error) => {
      return window.alert("오류 발생")
    },
  })

  const [ordercreated, { data: updatedata, error: errordata }] = useMutation(
    ORDER_MUTATION,
    {
      onCompleted: () => {
        window.alert('업데이트 완료')
        return window.location.reload()
      },

      onError: (errordata) => {
        return window.alert("오류 발생")
      
      },
    },
  )

  const startupdatemutationfordemend = () => {
    if (demendata !== null) {
      if (demendata.paidstatus == 'no') {
        ordercreated({
          variables: {
            id: Number(demendata.id),
            paidstatus: String('yes'),
          },
        })
      } else {
        ordercreated({
          variables: {
            id: Number(demendata.id),
            paidstatus: String('no'),
          },
        })
      }
    }
  }

  const onOpenModal = () => {
    setOpen(true)
  }

  const onCloseModal = () => {
    setOpen(false)
  }

  const handledemend = (arg) => {

    setdemendata(arg)

  
 

    setOpen(true)
  }

  const contactListColumns = [
    {
      text: '신청 날짜',
      dataField: 'createdAt',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div className="d-flex gap-3">
          {moment(new Date(item.createdAt)).format('YYYY-MM-DD-A hh:mm')}
        </div>
      ),
    },
    {
      text: '관련 주문 ID',
      dataField: 'orderId',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
    },
    {
      text: '고유 Id',
      dataField: 'id',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
    },
    {
      text: '입금현황',
      dataField: 'paidstatus',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          {item.paidstatus == 'no' ? (
            <button
              className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
              onClick={() => handledemend(item)}
            >
              {' '}
              변경
            </button>
          ) : (
            <span className="btn btn-success btn-sm btn-delete mb-0 b-r-4">
              {' '}
              입금완료
            </span>
          )}
        </div>
      ),
    },
    {
      text: '주문 상세',
      dataField: 'productQty',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          <Link to={`/sales/keeporders/${item.id}`}>상세보기</Link>
        </div>
      ),
    },
  ]

  return (
    <Fragment>
      {formState && (
        <>
          <Modal
            isOpen={open}
            toggle={onCloseModal}
            style={{ overlay: { opacity: 0.1 } }}
          >
            <ModalHeader toggle={onCloseModal}>
              <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                정산확인상태 변경
              </h5>
            </ModalHeader>
            <ModalBody>
              <Label htmlFor="recipient-name" className="col-form-label">
                현재 상태 : {demendata?.paidstatus}
              </Label>
              <h4>
                입금확인 상태를 {demendata?.paidstatus == 'no' ? 'yes' : 'no'}{' '}
                로 변경하시겠습니까?{' '}
              </h4>
              {/* <Input type="text"  readOnly  className="form-control" /> */}
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="primary"
                onClick={() => startupdatemutationfordemend()}
              >
                변경
              </Button>
              <Button
                type="button"
                color="secondary"
                onClick={() => onCloseModal('VaryingMdo')}
              >
                닫기
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
      {data?.keepOrderPaidlist && (
        <>
          <Breadcrumb title="마이창고 인출리스트" parent="Dashboard" />
          <Container fluid={true}>
            <Row>
              <Col xl="12 xl-100">
                <Card>
                  <CardHeader>
                    <h5>신청 목록(입금확인 완료)</h5>
                  </CardHeader>
                  <CardBody>
                    <div
                      className="table-responsive "
                      style={{
                        maxWidth: '100%',
                        overflowX: 'scroll',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {data.keepOrderPaidlist?.length > 0 && (
                        <PaginationProvider
                          pagination={paginationFactory({
                            sizePerPage: 500,
                            totalSize: data.keepOrderPaidlist.length, // replace later with size(users),
                            custom: true,
                          })}
                          keyField={'id'}
                          columns={contactListColumns}
                          data={data.keepOrderPaidlist}
                        >
                          {({ paginationProps, paginationTableProps }) => {
                            return (
                              <ToolkitProvider
                                keyField={'id'}
                                data={data.keepOrderPaidlist}
                                columns={contactListColumns}
                                bootstrap4
                                search
                              >
                                {(toolkitProps) => (
                                  <React.Fragment>
                                    <Row className="mb-2">
                                      <Col sm="4">
                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar
                                              {...toolkitProps.searchProps}
                                            />

                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col sm="8"></Col>
                                    </Row>

                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={'id'}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            defaultSorted={[
                                              {
                                                dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
                                                order: 'desc', // desc or asc
                                              },
                                            ]}
                                            classes={
                                              'table align-middle table-bordered table-hover text-centered'
                                            }
                                            bordered={false}
                                            striped={false}
                                            responsive
                                            ref={node}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )
                          }}
                        </PaginationProvider>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Fragment>
  )
}

// javascript:void(0)

export default Keepingorderlist
