import React, { Fragment, useState, useEffect, useRef } from 'react'
import Breadcrumb from './common/breadcrumb'
import { useQuery, useMutation } from '@apollo/client'
import { gql } from '@apollo/client'
import { Link } from 'react-router-dom'

import moment from 'moment'
import * as XLSX from 'xlsx'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
const GET_ORDER = gql`
  query orders($multiorder: String!) {
    orders(multiorder: $multiorder) {
      createdAt
      multiorder
      id
      item_price
      wholeamount
      name
      paidstatus
      shipping_amount
      product_main_image
      keepingamount
      productid

      User {
        name
        id
      }
    }
  }
`

const QUERY_ORDER_NUMBERS = gql`
  mutation OrderListAppByNumbers($multiorder: String!) {
    OrderListAppByNumbers(multiorder: $multiorder) {
      createdAt
      multiorder
      id
      item_price
      wholeamount
      name
      paidstatus
      shipping_amount
      product_main_image
      keepingamount
      productid

      User {
        name
        id
      }
    }
  }
`

const ORDER_MUTATION = gql`
  mutation updateOrder($id: Int!, $paidstatus: String!) {
    updateOrder(id: $id, paidstatus: $paidstatus) {
      id
    }
  }
`

const Orderlist = () => {
  var node = useRef()
  const [open, setOpen] = useState(false)

  const [orderdatadetail, setorderdatadetail] = useState()
  const [demendata, setdemendata] = useState()
  const [formState, setFormState] = useState({
    orderdata: '',
    users: '',
  })

  const [item, setitem] = useState('')

  const { SearchBar } = Search
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const handleStartDateChange = (date) => {
    if (endDate && date > endDate) {
      setEndDate(null)
    }
    setStartDate(date)
  }

  useEffect(() => {
    console.log('selectedIds', selectedIds)
  }, [selectedIds])

  const handleEndDateChange = (date) => {
    if (startDate && date < startDate) {
      setEndDate(null)
    } else {
      setEndDate(date)
    }
  }
  useEffect(() => {
    if (startDate && endDate && startDate > endDate) {
      setEndDate(null)
    }
  }, [startDate, endDate])

  var { loading, data } = useQuery(GET_ORDER, {
    variables: {
      multiorder: item,
    },
    onCompleted: (data) => {
      console.log('data.orders', data.orders)
      setFormState({
        ...formState,
        orderdata: data.orders,
      })
    },
    onError: (error) => {
      return window.alert('오류 발생')
    },
  })

  const [
    Download_excel_selected,
    {
      error: error_ordernumbers,
      data: data__ordernumbers,
      loading: loading__ordernumbers,
    },
  ] = useMutation(QUERY_ORDER_NUMBERS, {
    onCompleted: async (data__ordernumbers) => {
      console.log('data__ordernumbers', data__ordernumbers)
      if (data__ordernumbers) {
        console.log(
          'data__ordernumbers',
          data__ordernumbers?.OrderListAppByNumbers,
        )
        await downloadExcelFromBootstrapTable_selected(
          data__ordernumbers?.OrderListAppByNumbers,
        )
      }
    },
    onError: (error) => {
      console.log('error_ordernumbers', error_ordernumbers)
      // Handle error
    },
  })

  const downloadExcelFromBootstrapTable_selected = (data) => {
    // Sort the data by createdAt in ascending order
    const sortedData = data.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    )

    const workbook = XLSX.utils.book_new()
    const sheetData = sortedData.map((item) => {
      console.log('item', item)
      return {
        주문일: moment(new Date(item.createdAt)).format('YYYY-MM-DD-A hh:mm'),
        고객명: item.User.name,
        상품명: item.name,
        복수주문: item.multiorder == 'no' ? '아니오' : '예',
        수량: item.wholeamount,
        단가: item.item_price,
        합계: Number(item.item_price) * Number(item.wholeamount),
        출고수량: item.shipping_amount ? item.shipping_amount : 0,

        키핑수량: item.keepingamount ? item.keepingamount : 0,
        
        결제상태: item.paidstatus === 'yes' ? '결제완료' : '결제안함', // Translating paidstatus to order status
      }
    })

    const worksheet = XLSX.utils.json_to_sheet(sheetData)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')
    XLSX.writeFile(workbook, '주문데이터.xlsx')
  }

  const handleRefundStatusChange = () => {
    if (selectedIds.length > 0) {
      Download_excel_selected({
        variables: {
          multiorder: JSON.stringify(selectedIds),
        },
      })
    } else {
      window.alert('항목을 선택해주세요')
    }
  }

  const handlePeriodSearch = (arg) => {
    if (arg == 'refresh') {
      setitem('')
    } else if (arg == 'revenue') {
      if (startDate && endDate) {
        const formattedStartDate = moment(startDate).format(
          'YYYY-MM-DD HH:mm:ss.SSS',
        )
        const formattedEndDate = moment(endDate).format(
          'YYYY-MM-DD HH:mm:ss.SSS',
        )
        console.log('Start Date:', formattedStartDate)
        console.log('End Date:', formattedEndDate)

        const item =
          formattedStartDate && formattedEndDate
            ? JSON.stringify({
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                revenue: 'revenue',
              })
            : ''

        setitem(item)
      }
    } else {
      if (startDate && endDate) {
        const formattedStartDate = moment(startDate).format(
          'YYYY-MM-DD HH:mm:ss.SSS',
        )
        const formattedEndDate = moment(endDate).format(
          'YYYY-MM-DD HH:mm:ss.SSS',
        )
        console.log('Start Date:', formattedStartDate)
        console.log('End Date:', formattedEndDate)

        const item =
          formattedStartDate && formattedEndDate
            ? JSON.stringify({
                startDate: formattedStartDate,
                endDate: formattedEndDate,
              })
            : ''

        setitem(item)
      }
    }
  }

  const onOpenModal = () => {
    setOpen(true)
  }

  const onCloseModal = () => {
    setOpen(false)
  }

  const handleSelect = (row, isSelected) => {
    if (isSelected) {
      setSelectedIds((prevIds) => [...prevIds, row.id])
      console.log(`Row selected: ${row.id}`)
    } else {
      setSelectedIds((prevIds) => prevIds.filter((id) => id !== row.id))
      console.log(`Row deselected: ${row.id}`)
    }
  }

  const handleSelectAll = (isSelected, rows) => {
    if (isSelected) {
      const rowIds = rows.map((row) => row.id)
      setSelectedIds(rowIds)
      console.log('All rows selected')
    } else {
      setSelectedIds([])
      console.log('All rows deselected')
    }
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9', width: '40px !important' }, // 너비 조정
    headerStyle: {
      width: '40px !important', // 체크박스 헤더의 너비 조정
    },
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
  }

  const handledemend = (arg) => {
    setdemendata(arg)
    if (arg.multiorder.length > 2) {
      setorderdatadetail(JSON.parse(arg.multiorder))
    } else {
      setorderdatadetail(arg)
    }

    setOpen(true)
  }

  const [ordercreated, { data: updatedata, error: errordata }] = useMutation(
    ORDER_MUTATION,
    {
      onCompleted: () => {
        window.alert('업데이트 완료')
        return window.location.reload()
      },

      onError: (errordata) => {
        return window.alert('오류 발생')
      },
    },
  )

  const startupdatemutationfordemend = () => {
    if (demendata !== null) {
      if (demendata.paidstatus == 'no') {
        ordercreated({
          variables: {
            id: Number(demendata.id),
            paidstatus: String('yes'),
          },
        })
      } else {
        ordercreated({
          variables: {
            id: Number(demendata.id),
            paidstatus: String('no'),
          },
        })
      }
    }
  }

  const contactListColumns = [
    {
      text: '주문 날짜',
      dataField: 'date',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div className="d-flex gap-3">
          {moment(new Date(item.createdAt)).format('YYYY-MM-DD-A hh:mm')}
        </div>
      ),
    },
    {
      text: '주문 ID',
      dataField: 'id',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
    },
    {
      text: '성함',
      dataField: 'name',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => <div> {item.User.name}</div>,
    },

    {
      text: '입금상태 변경',
      dataField: 'paidstatus',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          {item.paidstatus == 'no' ? (
            <button
              className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
              onClick={() => handledemend(item)}
            >
              {' '}
              변경
            </button>
          ) : (
            <span className="btn btn-success btn-sm btn-delete mb-0 b-r-4">
              {' '}
              변경완료
            </span>
          )}
        </div>
      ),
    },

    {
      text: '주문 상세',
      dataField: 'shipping_amount',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          <Link to={`/sales/orders/${item.id}`}>상세보기</Link>
        </div>
      ),
    },
  ]

  return (
    <Fragment>
      {formState && (
        <>
          <Modal
            isOpen={open}
            toggle={onCloseModal}
            style={{ overlay: { opacity: 0.1 } }}
          >
            <ModalHeader toggle={onCloseModal}>
              <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                정산확인상태 변경
              </h5>
            </ModalHeader>
            <ModalBody>
              <Label htmlFor="recipient-name" className="col-form-label">
                현재 상태 : {demendata?.paidstatus}
              </Label>
              <h4>
                입금확인 상태를 {demendata?.paidstatus == 'no' ? 'yes' : 'no'}{' '}
                로 변경하시겠습니까?{' '}
              </h4>
              {/* <Input type="text"  readOnly  className="form-control" /> */}
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="primary"
                onClick={() => startupdatemutationfordemend()}
              >
                변경
              </Button>
              <Button
                type="button"
                color="secondary"
                onClick={() => onCloseModal('VaryingMdo')}
              >
                닫기
              </Button>
            </ModalFooter>
          </Modal>
          <Breadcrumb title="주문리스트" parent="Dashboard" />
          <Container fluid={true}>
            <Row>
              <Col xl="12 xl-100">
                <Card>
                  <CardHeader>
                    <h5>주문 목록</h5>
                  </CardHeader>
                  <CardBody>
                    <div
                      className="table-responsive "
                      style={{
                        maxWidth: '100%',
                        overflowX: 'scroll',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {formState.orderdata && (
                        <PaginationProvider
                          pagination={paginationFactory({
                            sizePerPage: 500,
                            totalSize: formState.orderdata.length, // replace later with size(users),
                            custom: true,
                          })}
                          keyField={'id'}
                          columns={contactListColumns}
                          data={formState.orderdata}
                        >
                          {({ paginationProps, paginationTableProps }) => {
                            return (
                              <ToolkitProvider
                                keyField={'id'}
                                data={formState.orderdata}
                                columns={contactListColumns}
                                bootstrap4
                                search
                              >
                                {(toolkitProps) => (
                                  <React.Fragment>
                                    <Row className="mb-2">
                                      <Col sm="4">
                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar
                                              {...toolkitProps.searchProps}
                                            />
                                            <button
                                              className="ml-2 btn btn-primary"
                                              style={{
                                                backgroundColor:
                                                  'rgb(88, 86, 214) !important',
                                              }}
                                              onClick={() =>
                                                handlePeriodSearch('refresh')
                                              }
                                            >
                                              데이터 초기화
                                            </button>
                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col className="text-left" sm="8">
                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <div>
                                              <strong>시작날짜:</strong>
                                              <DatePicker
                                                selected={startDate}
                                                onChange={handleStartDateChange}
                                                startDate={startDate}
                                                selectsStart
                                                placeholderText="시작날짜 선택"
                                                shouldCloseOnSelect={true}
                                                dateFormat="yyyy-MM-dd"
                                              />

                                              {startDate && (
                                                <>
                                                  <strong className="ml-3">
                                                    종료날짜:
                                                  </strong>
                                                  <DatePicker
                                                    selected={endDate}
                                                    onChange={
                                                      handleEndDateChange
                                                    }
                                                    endDate={endDate}
                                                    selectsEnd
                                                    minDate={startDate}
                                                    placeholderText="종료날짜 선택"
                                                    shouldCloseOnSelect={true}
                                                    dateFormat="yyyy-MM-dd"
                                                  />
                                                </>
                                              )}
                                              {startDate && endDate && (
                                                <Button
                                                  className="ml-2"
                                                  onClick={() =>
                                                    handlePeriodSearch()
                                                  }
                                                >
                                                  기간 조회
                                                </Button>
                                              )}
                                              {/* {startDate && endDate && (
                                                <Button
                                                  className="ml-2"
                                                  onClick={() =>
                                                    handlePeriodSearch(
                                                      'revenue',
                                                    )
                                                  }
                                                >
                                                  매출 조회
                                                </Button>
                                              )} */}
                                            </div>

                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col sm="12">
                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <button
                                              disabled={loading__ordernumbers}
                                              className="btn btn-success"
                                              style={{
                                                borderRadius: '5px',
                                                fontSize: '13px',
                                                margin: '5px',
                                              }}
                                              onClick={() =>
                                                handleRefundStatusChange()
                                              }
                                            >
                                              {loading__ordernumbers ? (
                                                <>
                                                  <div className="d-flex justify-content-center align-items-center">
                                                    <Spinner
                                                      color="black"
                                                      style={{
                                                        height: '1rem',
                                                        width: '1rem',
                                                      }}
                                                      type="grow"
                                                    />
                                                    Loading
                                                  </div>
                                                </>
                                              ) : (
                                                '      엑셀 다운로드(선택항목)'
                                              )}
                                            </button>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={'id'}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            defaultSorted={[
                                              {
                                                dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
                                                order: 'desc', // desc or asc
                                              },
                                            ]}
                                            classes={
                                              'table align-middle table-bordered table-hover text-centered'
                                            }
                                            bordered={false}
                                            striped={false}
                                            responsive
                                            ref={node}
                                            selectRow={selectRow}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )
                          }}
                        </PaginationProvider>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Fragment>
  )
}

// javascript:void(0)

export default Orderlist
