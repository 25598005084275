import React, { Fragment, useState } from 'react'
import Breadcrumb from './common/breadcrumb'
import { useQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import { Link } from 'react-router-dom'
import { Navigation, Box } from 'react-feather'
import CountUp from 'react-countup'

import moment from 'moment'

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Media,
  Row,
  Table,
} from 'reactstrap'

const GET_ORDER = gql`
  query orders {
    orders {
      createdAt
      multiorder
      id
      paidstatus
      User {
        name
      }
    }
  }
`
const GET_USERS = gql`
  query users {
    users {
      id
      createdAt
      name
      email
    }
  }
`

const Dashboard = () => {
  const [formState, setFormState] = useState({
    orderdata: '',
    users: '',
  })

  var { loading, data } = useQuery(GET_ORDER, {
    onCompleted: (data) => {
      setFormState({
        ...formState,
        orderdata: data.orders,
      })
    },
    onError: (error) => {
      return window.alert('오류 발생')
    },
  })
  var { data: data1 } = useQuery(GET_USERS, {
    onCompleted: (data1) => {
      setFormState({
        ...formState,
        users: data1.users,
      })
    },
    onError: (error) => {
      return window.alert('오류 발생')
    },
  })

  return (
    <Fragment>
      {formState && (
        <>
          <Breadcrumb title="Dashboard" parent="Dashboard" />
          <Container fluid={true}>
            <Row>
              <Col xl="6 xl-50" md="6">
                <Card className=" o-hidden widget-cards">
                  <CardBody className="bg-warning">
                    <Media className="static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <Navigation className="font-warning" />
                        </div>
                      </div>
                      <Media body className="col-8">
                        <span className="m-0">총 주문수량</span>
                        <h3 className="mb-0">
                          <CountUp
                            className="counter"
                            end={formState.orderdata.length}
                          />
                          {/* <small> This Month</small> */}건
                        </h3>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="6 xl-50" md="6">
                <Card className=" o-hidden  widget-cards">
                  <CardBody className="bg-secondary ">
                    <Media className="static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <Box className="font-secondary" />
                        </div>
                      </div>
                      <Media body className="col-8">
                        <span className="m-0">회원 수</span>
                        <h3 className="mb-0">
                          <CountUp
                            className="counter"
                            end={formState.users.length - 1}
                          />
                          명{/* <small> This Month</small> */}
                        </h3>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="12 xl-100">
                <Card>
                  <CardHeader>
                    <h5>최근 주문</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="user-status table-responsive latest-order-table">
                      <Table borderless>
                        <thead>
                          <tr>
                            <th scope="col">주문 날짜</th>
                            <th scope="col">주문 ID</th>
                            <th scope="col">주문자</th>

                            <th scope="col">입금확인</th>
                            <th scope="col">주문상세</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formState.orderdata &&
                            formState.orderdata.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {moment(new Date(item.createdAt)).format(
                                    'YYYY-MM-DD-A hh:mm',
                                  )}
                                </td>
                                <td>{item.id}</td>
                                <th scope="row" className="drag-pointer">
                                  {item.User.name}
                                </th>

                                <td> {item.paidstatus}</td>
                                <td>
                                  {' '}
                                  <Link to="mutikart-admin/dashboard">
                                    상세보기
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      <a href="#javaScript" className="btn btn-primary">
                        모든 주문 보기
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="12 xl-100">
                <Card>
                  <CardHeader>
                    <h5>최근가입 회원</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="user-status table-responsive latest-order-table">
                      <Table borderless>
                        <thead>
                          <tr>
                            <th scope="col">가입 날짜</th>
                            <th scope="col">회원 ID</th>
                            <th scope="col">성함</th>

                            <th scope="col">이메일</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formState.users !== '' &&
                            formState.users.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {moment(new Date(item.createdAt)).format(
                                    'YYYY-MM-DD-A hh:mm',
                                  )}
                                </td>
                                <td>{item.id}</td>

                                <td> {item.name}</td>
                                <td> {item.email}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      <a href="#javaScript" className="btn btn-primary">
                        모든 유저 보기
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Fragment>
  )
}

// javascript:void(0)

export default Dashboard
