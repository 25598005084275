import React, { Fragment, useState, useRef } from 'react'
import Breadcrumb from '../common/breadcrumb'

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Media,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import { useParams } from 'react-router-dom'
import { useQuery, useLazyQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import moment from 'moment'
import { Link } from 'react-router-dom'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'

const GET_ORDER = gql`
  query ordersbyorderid($id: Int!) {
    ordersbyorderid(id: $id) {
      id
      createdAt
      product_main_image
      name
      keepingamount
      wholeamount
      multiorder
      shipping_amount
      created_at
      updated_at
      item_price
      paidstatus
      userId
      messagefromcustomer
      orderaddress
    }
  }
`

const Orders = (props) => {
  var node = useRef()
  const { SearchBar } = Search
  const imgStyle = {
    maxHeight: 128,
  }

  const handle = useParams()
  const [open, setOpen] = useState(false)
  const [orderdataid, setorderdataid] = useState()
  const [orderdata, setorderdata] = useState()
  const [orderdatadetail, setorderdatadetail] = useState()
  const [formState, setFormState] = useState({
    id: '',
    users: '',
  })
  const onOpenModal = () => {
    setOpen(true)
  }

  const onCloseModal = () => {
    setOpen(false)
  }

  var { loading, data } = useQuery(GET_ORDER, {
    variables: {
      id: Number(props.match.params.id),
    },
    onCompleted: (data) => {
   
      if (data.ordersbyorderid[0].multiorder.length > 2) {
        setorderdata(data.ordersbyorderid[0])
        setorderdatadetail(JSON.parse(data.ordersbyorderid[0].multiorder))
 
      } else {
     
        setorderdata(data.ordersbyorderid[0])
      }
    },
    onError: (error) => {
      return window.alert("오류 발생")
    },
  })

  const contactListColumns = [
    {
      text: '이미지',
      dataField: 'images',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div className="d-flex gap-3">
          <a
            target="_blank"
            href={`https://www.yoyomo.co.kr/product-details/` + item.id}
          >
            <div
              style={{
                height: '90px !important',
              }}
            >
              <Media style={imgStyle} src={item.images} alt="" />
            </div>
          </a>
        </div>
      ),
    },
    {
      text: '상품 ID',
      dataField: 'id',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
    },
    {
      text: '상품명',
      dataField: 'title',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => <div> {item.title}</div>,
    },

    {
      text: '키핑 수량',
      dataField: 'qtyforkeep',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => <div> {item.qtyforkeep}개</div>,
    },

    {
      text: '발송요청 수량',
      dataField: 'userId',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>{Number(item.qty) - Number(item.qtyforkeep)}개</div>
      ),
    },
    {
      text: '총 수량',
      dataField: 'qty',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => <div>{item.qty}개</div>,
    },
  ]

  return (
    <Fragment>
      <Breadcrumb title="Orders" parent="Sales" />

      <Container fluid={true}>
        {orderdata && (
          <>
           
                <Row>
                  <Col md="12">
                    <div
                      className="success-text text-left"
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {/* <i className="fa fa-check-circle" aria-hidden="true"></i> */}

                      <p>
                        주문날짜 -
                        {moment(new Date(orderdata.createdAt)).format(
                          'YYYY-MM-DD-A hh:mm',
                        )}
                      </p>
                      <p>
                        주문번호 - {props.match.params.id}번{}
                      </p>
                      <p>
                        메세지 - {orderdata?.messagefromcustomer == "" ? "없음" : orderdata?.messagefromcustomer }
                      </p>
                      <p>
                        수취인 주소 - {orderdata?.orderaddress}
                      </p>
                      <p>
                        입금확인 -{' '}
                        {orderdata.paidstatus == 'no' ? '확인전' : '확인완료'}
                        {}
                      </p>

                 
                    </div>

                    {orderdatadetail !== undefined ? (
                      <>
                        <div className="checkout-details">
                          <div className="order-box">
                            {orderdatadetail !== undefined &&
                              orderdatadetail.map((item, index) => (
                                <ul className="qty" key={index}>
                                  <li>
                                    <Media
                                      style={imgStyle}
                                      src={item.images}
                                      alt=""
                                    />
                                  </li>
                                  <br />
                                  <li>
                                    {item.title} × {item.qty}개
                                  </li>
                                  <br />
                                  <li>
                                    개당 가격 :{' '}
                                    {Number(item.price)
                                      .toFixed(0)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    원
                                  </li>
                                  <br />
                                  <li>키핑 수량 : {item.qtyforkeep}개</li>
                                  <br />
                                  <li>
                                    주문 수량 :{' '}
                                    {Number(item.qty) - Number(item.qtyforkeep)}
                                    개
                                  </li>
                                  <br />
                                  <li>
                                    합계 :{' '}
                                    {(Number(item.qty) * Number(item.price))
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    원
                                  </li>
                                  <br />
                                  <br />
                                  <br />
                                </ul>
                              ))}

                            <ul className="sub-total">
                              <li>
                                {Number(orderdata.item_price) >= 90000 ? (
                                  <>배송비 : 무료(90,000원 이상 구매 시 무료)</>
                                ) : (
                                  <> 배송비: 3,500원</>
                                )}
                              </li>
                              <br />
                            </ul>
                            <ul className="sub-total">
                              <li>
                                총 합계 :
                                {Number(orderdata.item_price) >= 90000 ? (
                                  <>
                                    {Number(orderdata.item_price)
                                      .toFixed(0)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    원
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    {Number(Number(orderdata.item_price) + 3500)
                                      .toFixed(0)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    원
                                  </>
                                )}
                              </li>
                              <br />
                            </ul>
                            <ul>
                              <a
                                className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
                                href="/orderlist"
                              >
                                주문 목록
                              </a>
                            </ul>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="checkout-details">
                          <div className="order-box">
                            <ul className="qty">
                              <li>
                                <Media
                                  style={imgStyle}
                                  src={orderdata.product_main_image}
                                  alt=""
                                />
                              </li>
                              <br />
                              <li>
                                {orderdata.name} × {orderdata.wholeamount}개
                              </li>
                              <br />
                              <li>
                                개당 가격 :{' '}
                                {Number(orderdata.item_price)
                                  .toFixed(0)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                원
                              </li>
                              <br />
                              <li>키핑 수량 : {orderdata.keepingamount}개</li>
                              <br />
                              <li>
                                주문 수량 :{' '}
                                {Number(orderdata.wholeamount) -
                                  Number(orderdata.keepingamount)}
                                개
                              </li>
                              <br />
                              <li>
                               <strong> 합계 :{' '}</strong>
                                {(
                                  Number(orderdata.item_price).toFixed(0) *
                                  orderdata.wholeamount
                                )
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                원
                              </li>
                              <br />
                            </ul>

                            <ul className="sub-total">
                              <li>
                                {Number(orderdata.item_price).toFixed(0) *
                                  orderdata.wholeamount >=
                                90000 ? (
                                  <>배송비 : 무료(90,000원 이상 구매 시 무료)</>
                                ) : (
                                  <> 배송비: 3,500원</>
                                )}
                              </li>
                              <br />
                            </ul>
                            <ul className="sub-total">
                              <li>

                                총 합계 :
                                {Number(orderdata.item_price).toFixed(0) *
                                  orderdata.wholeamount >=
                                90000 ? (
                                  <>
                                    {(
                                      Number(orderdata.item_price).toFixed(0) *
                                      orderdata.wholeamount
                                    )
                                      .toFixed(0)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    원
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    {Number(
                                      Number(orderdata.item_price).toFixed(0) *
                                        orderdata.wholeamount +
                                        3500,
                                    )
                                      .toFixed(0)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    원
                                  </>
                                )}
                              </li>
                              <br />
                            </ul>
                            <ul className="total">
                              <a
                                className="btn btn-purple " style={{marginTop:"30px" , marginBottom:"30px"}}
                                href="/page/account/orderdata"
                              >
                                주문 목록
                              </a>
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
           
          </>
        )}
      </Container>
    </Fragment>
  )
}

export default Orders
