import React, { Fragment, useState, useRef } from 'react'
import Breadcrumb from './common/breadcrumb'

import moment from 'moment'

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  CardTitle,
  Input,
} from 'reactstrap'

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'
import { gql, useMutation, useQuery } from '@apollo/client'

const GET_USERS = gql`
  query users {
    users {
      id
      createdAt
      name
      email
      phonenumber
      businesscard
      recommendname
      recommendphonenumber
      sample1name
      sample1phonenumber
      sample2name
      sample2phonenumber
      approved
      address
    }
  }
`

const CHANGE_GROUP = gql`
  mutation changeuserapprove($id: Int!, $approved: String) {
    changeuserapprove(id: $id, approved: $approved) {
      id
    }
  }
`

const UPDATE_INFO = gql`
  mutation updateUserInfo(
    $id: Int!
    $name: String!
    $phonenumber: String
    $address: String
    $recommendname: String
    $recommendphonenumber: String
    $sample1name: String
    $sample1phonenumber: String
    $sample2name: String
    $sample2phonenumber: String
  ) {
    updateUserInfo(
      id: $id
      name: $name
      phonenumber: $phonenumber
      address: $address
      recommendname: $recommendname
      recommendphonenumber: $recommendphonenumber
      sample1name: $sample1name
      sample1phonenumber: $sample1phonenumber
      sample2name: $sample2name
      sample2phonenumber: $sample2phonenumber
    ) {
      id
    }
  }
`

const CREATE_RECOMMENDLIST_MUTATION = gql`
  mutation CreateRecommendList($userId: Int!, $recomendId: Int!) {
    createrecommendList(userId: $userId, recomendId: $recomendId) {
      id
    }
  }
`

const GET_RECOMMEND_LISTS = gql`
  query getRecommendList($userId: Int!) {
    getRecommendList(userId: $userId) {
      id
      createdAt
      userId
      recomendId
      userName
      recomendName
      userEmail
      recomendEmail
    }
  }
`

const DELETE_RECOMMEND_LIST = gql`
  mutation deleterecommendList($id: Int!) {
    deleterecommendList(id: $id) {
      id
    }
  }
`

// const UPDATE_INFO = gql`
//   mutation changeUserInfo($id: Int!, $name: String!) {
//     changeUserInfo(id: $id, name: $name) {
//       id
//     }
//   }
// `

const Userlist = () => {
  var node = useRef()
  const [formState, setFormState] = useState({
    userinfo: '',
    users: '',
    status: null,
    recomendId: null,
    recomendViewId: null,
    recomendViewList: null,
    recomendViewIdDelete: null,
  })

  const [opendelete, setOpendelete] = useState(false)
  const onCloseModaldelete = () => {
    setOpendelete(false)
  }

  const handleInputChange = (event, fieldName) => {
    const newValue = event.target.value
    setFormState((prevState) => ({
      ...prevState,
      userinfo: {
        ...prevState.userinfo,
        [fieldName]: newValue,
      },
    }))
  }

  console.log('formstate', formState)
  const [modal, setModal] = useState(false)
  const toggle = async () => {
    await setModal(!modal)
  }

  const [infochangeAll, { data2233, error2243 }] = useMutation(UPDATE_INFO, {
    onCompleted: (data2233) => {
      window.alert('변경 완료')
      window.location.reload()
    },
    onError: (error2243) => {
      console.log('error2243', error2243)
      window.alert('에러 발생')
    },
  })

  const startCreate = async () => {
    console.log('start', formState.userinfo)
    if (formState.userinfo) {
      const {
        id,
        name,
        phonenumber,
        address,
        recommendname,
        recommendphonenumber,
        sample1name,
        sample1phonenumber,
        sample2name,
        sample2phonenumber,
      } = formState.userinfo
      console.log('name', name)
      if (
        name.length < 2 ||
        phonenumber.length < 2 ||
        address.length < 2 ||
        recommendname.length < 2 ||
        recommendphonenumber.length < 2 ||
        sample1name.length < 2 ||
        sample1phonenumber.length < 2 ||
        sample2name.length < 2 ||
        sample2phonenumber.length < 2
      ) {
        return window.alert('최소 2자 이상 입력해주세요')
      }

      infochangeAll({
        variables: {
          id: Number(id),
          name: String(name),
          phonenumber: String(phonenumber),
          address: String(address),
          recommendname: String(recommendname),
          recommendphonenumber: String(recommendphonenumber),
          sample1name: String(sample1name),
          sample1phonenumber: String(sample1phonenumber),
          sample2name: String(sample2name),
          sample2phonenumber: String(sample2phonenumber),
        },
      })
    }
  }

  const [createRecommendList] = useMutation(CREATE_RECOMMENDLIST_MUTATION)
  const {
    loading: recloading,
    error: recerror,
    data: recdata,
  } = useQuery(GET_RECOMMEND_LISTS, {
    variables: { userId: Number(formState?.recomendViewId) },
    onCompleted: (recdata) => {
      if (recdata) {
        setFormState({
          ...formState,
          recomendViewList: recdata.getRecommendList,
        })
      }
    },
  })

  const [
    deleteRecommendList,
    { data: ermdata, loading: ermloading, error: ermerror },
  ] = useMutation(DELETE_RECOMMEND_LIST)

  console.log('recdatarecdata', recdata?.getRecommendList)
  const contactListColumns = [
    {
      text: '가입 날짜',
      dataField: 'date',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div className="d-flex gap-3">
          {moment(new Date(item.createdAt)).format('YYYY-MM-DD-A hh:mm')}
        </div>
      ),
    },
    {
      text: '정보보기',
      dataField: 'id',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          <Button
            color="secondary"
            type="button"
            onClick={() => onClicInfo(item)}
          >
            정보보기
          </Button>
        </div>
      ),
    },
    {
      text: '성함',
      dataField: 'name',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
    },
    {
      text: '이메일',
      dataField: 'email',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
    },
    {
      text: '연락처',
      dataField: 'phonenumber',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
    },
    {
      text: '주소',
      dataField: 'address',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
    },
    {
      text: '사업자 등록증',
      dataField: 'businesscard',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          {' '}
          <a href={item.businesscard} target="_blank">
            미리보기
          </a>{' '}
        </div>
      ),
    },
    {
      text: '활성화 여부',
      dataField: 'approved',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          {item.approved == 'no' ? (
            <Button
              color="primary"
              type="button"
              onClick={() => onClickDelete(item)}
            >
              비활성
            </Button>
          ) : (
            <Button
              color="secondary"
              type="button"
              onClick={() => onClickDelete(item)}
            >
              활성
            </Button>
          )}
        </div>
      ),
    },
    {
      text: '추천인 등록',
      dataField: 'recommendphonenumber',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          <Button
            color="primary"
            type="button"
            onClick={() => onClickDelete(item, '추천')}
          >
            등록하기
          </Button>
        </div>
      ),
    },

    {
      text: '추천인 보기',
      dataField: 'sample1phonenumber',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          <Button
            color="primary"
            type="button"
            onClick={() => onClickDelete(item, '추천인보기')}
          >
            보기
          </Button>
        </div>
      ),
    },

    {
      text: '추천인 정보',
      dataField: 'recommendname',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          {' '}
          성함 : {item.recommendname} / 연락처 : {item.recommendphonenumber}
        </div>
      ),
    },
    {
      text: '샘플받으실 분(1)',
      dataField: 'sample1name',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          {' '}
          성함 : {item.sample1name} / 연락처 : {item.sample1phonenumber}
        </div>
      ),
    },
    {
      text: '샘플받으실 분(2)',
      dataField: 'sample2phonenumber',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          {' '}
          성함 : {item.sample2name} / 연락처 : {item.sample2phonenumber}
        </div>
      ),
    },
  ]

  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        console.log(`Selected ID: ${row.id}`)

        setFormState({
          ...formState,
          recomendId: row.id,
        })
      }
    },
  }

  const deleteselectRow = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        console.log(`Selected ID: ${row.id}`)

        setFormState({
          ...formState,
          recomendViewIdDelete: row.id,
        })
      }
    },
  }

  const listColumns = [
    {
      text: '가입 날짜',
      dataField: 'date',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div className="d-flex gap-3">
          {moment(new Date(item.createdAt)).format('YYYY-MM-DD-A hh:mm')}
        </div>
      ),
    },

    {
      text: '성함',
      dataField: 'name',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
    },
    {
      text: '이메일',
      dataField: 'email',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
    },
    {
      text: '추천인 등록 현황',
      dataField: 'id',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div>
          <Button
            color="secondary"
            type="button"
            onClick={() => onClicInfo(item)}
          >
            보기
          </Button>
        </div>
      ),
    },
  ]

  const recomendlistColumns = [
    {
      text: '추천 날짜',
      dataField: 'id',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, item) => (
        <div className="d-flex gap-3">
          {moment(new Date(item.createdAt)).format('YYYY-MM-DD-A hh:mm')}
        </div>
      ),
    },
    {
      text: '성함',
      dataField: 'recomendName',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
    },
    {
      text: '이메일',
      dataField: 'recomendEmail',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
    },
  ]

  const onClickDelete = (user, args) => {
    if (args) {
      if (args == '추천') {
        setFormState({
          ...formState,
          userinfo: user,
          status: '추천',
        })

        setOpendelete(true)
      } else if (args == '추천인보기') {
        setFormState({
          ...formState,
          userinfo: user,
          recomendViewId: user.id,
          status: '추천인보기',
        })

        setOpendelete(true)
      }
      //추천인 버튼 누른 경우
    } else {
      //활성화 누른 경우
      setFormState({
        ...formState,
        userinfo: user,
        status: '활성',
      })

      setOpendelete(true)
    }
  }

  const onClicInfo = (user) => {
    setFormState({
      ...formState,
      userinfo: user,
    })
    setModal(true)
  }

  const { SearchBar } = Search

  var { data: data1 } = useQuery(GET_USERS, {
    onCompleted: (data1) => {
      setFormState({
        ...formState,
        users: data1.users,
      })
    },
    onError: (error) => {
      return window.alert('오류 발생')
    },
  })

  const startdeletebyidmutation = async (arg) => {
    if (arg == '추천') {
      //추천인 뮤테이션 누른 경우
      if (!formState.recomendId) {
        return window.alert('추천인을 선택해주세요')
      } else {
        try {
          const { data } = await createRecommendList({
            variables: {
              userId: Number(formState.userinfo?.id),
              recomendId: Number(formState.recomendId),
            },
          })

          // 뮤테이션 호출이 성공하면 data에 결과가 들어 있습니다.
          console.log('RecommendList created:', data.createrecommendList)
          window.alert('등록 완료')
          return window.location.reload()
        } catch (error) {
          console.log('Error creating RecommendList:', error)
        }
      }
    } else if (arg == '추천인보기') {
      try {
        const result = await deleteRecommendList({
          variables: { id: Number(formState?.recomendViewIdDelete) },
        })
        console.log(result)
        await window.alert('삭제 완료')
        return window.location.reload()
        // handle further logic, maybe refetch queries or update UI
      } catch (err) {
        console.error('Error deleting the list:', err)
      }
    } else {
      //활성화 여부 누른 경우
      if (formState.userinfo.approved == 'no') {
        changebyid({
          variables: {
            id: Number(formState.userinfo.id),
            approved: String('yes'),
          },
        })
      } else {
        changebyid({
          variables: {
            id: Number(formState.userinfo.id),
            approved: String('no'),
          },
        })
      }
    }
  }

  const [changebyid, { data223, error223 }] = useMutation(CHANGE_GROUP, {
    onCompleted: (data223) => {
      window.alert('변경 완료')
      window.location.reload()
    },
    onError: (error223) => {
      window.alert('에러 발생')
    },
  })

  return (
    <Fragment>
      {formState && (
        <>
          <Breadcrumb title="유저리스트" parent="Dashboard" />
          <Container fluid={true}>
            <Row>
              <Col xl="12 xl-100">
                <Card>
                  <CardHeader>
                    <h5> 회원 리스트 - 총 {formState.users.length}명</h5>
                  </CardHeader>
                  <CardBody>
                    <div
                      className="table-responsive "
                      style={{
                        maxWidth: '100%',
                        overflowX: 'scroll',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {formState.users?.length > 0 && (
                        <PaginationProvider
                          pagination={paginationFactory({
                            sizePerPage: 500,
                            totalSize: formState.users.length, // replace later with size(users),
                            custom: true,
                          })}
                          keyField={'id'}
                          columns={contactListColumns}
                          data={formState.users}
                        >
                          {({ paginationProps, paginationTableProps }) => {
                            return (
                              <ToolkitProvider
                                keyField={'id'}
                                data={formState.users}
                                columns={contactListColumns}
                                bootstrap4
                                search
                              >
                                {(toolkitProps) => (
                                  <React.Fragment>
                                    <Row className="mb-2">
                                      <Col sm="4">
                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar
                                              {...toolkitProps.searchProps}
                                            />

                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col sm="8"></Col>
                                    </Row>

                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={'id'}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            defaultSorted={[
                                              {
                                                dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
                                                order: 'desc', // desc or asc
                                              },
                                            ]}
                                            classes={
                                              'table align-middle table-bordered table-hover text-centered'
                                            }
                                            bordered={false}
                                            striped={false}
                                            responsive
                                            ref={node}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )
                          }}
                        </PaginationProvider>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {formState && (
        <>
          <Modal
            isOpen={modal}
            toggle={toggle}
            style={{
              margin: 'auto',
            }}
          >
            <ModalBody>
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">유저 정보</CardTitle>
                  <hr />
                  <Label className="mt-2">
                    <span style={{ color: 'red' }}>*</span>
                    성함
                  </Label>
                  <Input
                    value={formState?.userinfo?.name}
                    onChange={(e) => handleInputChange(e, 'name')}
                    placeholder="Category One in English"
                  />
                  <hr />
                  <Label className="mt-2">
                    <span style={{ color: 'red' }}>*</span>
                    이메일
                  </Label>
                  <Input
                    value={formState?.userinfo?.email}
                    onChange={(e) => handleInputChange(e, 'email')}
                    placeholder="Category One in Chinese"
                    readOnly
                  />
                  <hr />
                  <Label className="mt-2">
                    <span style={{ color: 'red' }}>*</span>
                    연락처
                  </Label>
                  <Input
                    value={formState?.userinfo?.phonenumber}
                    onChange={(e) => handleInputChange(e, 'phonenumber')}
                    placeholder="Category Two in English"
                  />
                  <hr />
                  <Label className="mt-2">
                    <span style={{ color: 'red' }}>*</span>
                    추천인 이름
                  </Label>
                  <Input
                    value={formState?.userinfo?.recommendname}
                    onChange={(e) => handleInputChange(e, 'recommendname')}
                    placeholder="Category Two in Chinese"
                  />
                  <hr />
                  <Label className="mt-2">
                    <span style={{ color: 'red' }}>*</span>추천인 연락처
                  </Label>
                  <Input
                    value={formState?.userinfo?.recommendphonenumber}
                    onChange={(e) =>
                      handleInputChange(e, 'recommendphonenumber')
                    }
                    placeholder="Maker"
                  />
                  <hr />
                  <Label className="mt-2">
                    <span style={{ color: 'red' }}>*</span>주소
                  </Label>
                  <Input
                    value={formState?.userinfo?.address}
                    onChange={(e) => handleInputChange(e, 'address')}
                    placeholder="Pet Name"
                  />
                  <hr />
                  <Label className="mt-2">
                    <span style={{ color: 'red' }}>*</span>샘플 성함1
                  </Label>
                  <Input
                    value={formState?.userinfo?.sample1name}
                    onChange={(e) => handleInputChange(e, 'sample1name')}
                    placeholder="Model"
                  />
                  <hr />
                  <Label className="mt-2">
                    <span style={{ color: 'red' }}>*</span>샘플 연락처1
                  </Label>
                  <Input
                    value={formState?.userinfo?.sample1phonenumber}
                    onChange={(e) => handleInputChange(e, 'sample1phonenumber')}
                    placeholder="Item Index"
                  />
                  <Label className="mt-2">
                    <span style={{ color: 'red' }}>*</span>샘플 성함2
                  </Label>
                  <Input
                    value={formState?.userinfo?.sample2name}
                    onChange={(e) => handleInputChange(e, 'sample2name')}
                    placeholder="Model"
                  />
                  <hr />
                  <Label className="mt-2">
                    <span style={{ color: 'red' }}>*</span>샘플 연락처2
                  </Label>
                  <Input
                    value={formState?.userinfo?.sample2phonenumber}
                    onChange={(e) => handleInputChange(e, 'sample2phonenumber')}
                    placeholder="Item Index"
                  />
                </CardBody>
              </Card>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="primary"
                onClick={() => startCreate()}
              >
                변경
              </Button>
              <Button
                type="button"
                color="secondary"
                onClick={() => setModal(!modal)}
              >
                닫기
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={opendelete}
            toggle={onCloseModaldelete}
            className={
              formState.status === '추천' || '추천인현황' ? 'modal-xl' : ''
            }
            style={{ overlay: { opacity: 0.1 } }}
          >
            <ModalBody>
              <Label htmlFor="recipient-name" className="col-form-label">
                {formState.status === '추천' && (
                  <>{formState.userinfo.name}님 추천인 등록</>
                )}

                {formState.status === '추천인보기' && (
                  <>{formState.userinfo.name}님 추천인 현황</>
                )}

                {formState.status === '활성' &&
                  (formState.userinfo.approved === 'no' ? (
                    <>{formState.userinfo.name} 님을 활성화시키시겠습니까?</>
                  ) : (
                    <>{formState.userinfo.name} 님을 비활성화시키시겠습니까?</>
                  ))}
              </Label>

              {formState.status === '추천인보기' && (
                <>
                  {formState.recomendViewList?.length === 0 ||
                  !formState.recomendViewList ? (
                    <div>없음</div>
                  ) : (
                    <PaginationProvider
                      pagination={paginationFactory({
                        sizePerPage: 500,
                        totalSize: formState.recomendViewList?.length,
                        custom: true,
                      })}
                      keyField={'id'}
                      columns={recomendlistColumns}
                      data={formState.recomendViewList}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField={'id'}
                          data={formState.recomendViewList}
                          columns={recomendlistColumns}
                          bootstrap4
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  {formState.status == '추천인보기' && (
                                    <Button
                                      type="button"
                                      color="primary"
                                      onClick={() =>
                                        startdeletebyidmutation('추천인보기')
                                      }
                                    >
                                      선택항목 추천인 삭제
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={'id'}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      selectRow={deleteselectRow}
                                      defaultSorted={[
                                        {
                                          dataField: 'id',
                                          order: 'desc',
                                        },
                                      ]}
                                      classes={
                                        'table align-middle table-bordered table-hover text-centered'
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}
                </>
              )}

              {formState.status === '추천' && formState.users?.length > 0 && (
                <PaginationProvider
                  pagination={paginationFactory({
                    sizePerPage: 500,
                    totalSize: formState.users.length, // 나중에 size(users)로 대체하세요.
                    custom: true,
                  })}
                  keyField={'id'}
                  columns={listColumns}
                  data={formState.users}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField={'id'}
                      data={formState.users}
                      columns={listColumns}
                      bootstrap4
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box ms-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              {formState.status == '추천' && (
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={() =>
                                    startdeletebyidmutation('추천')
                                  }
                                >
                                  선택항목 추천인 등록
                                </Button>
                              )}
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  selectRow={selectRow}
                                  keyField={'id'}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  defaultSorted={[
                                    {
                                      dataField: 'id',
                                      order: 'desc',
                                    },
                                  ]}
                                  classes={
                                    'table align-middle table-bordered table-hover text-centered'
                                  }
                                  bordered={false}
                                  striped={false}
                                  responsive
                                  ref={node}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              )}

              {/* <Input type="text"  readOnly  className="form-control" /> */}
            </ModalBody>
            <ModalFooter>
              {formState.status == '활성' && (
                <Button
                  type="button"
                  color="primary"
                  onClick={() => startdeletebyidmutation()}
                >
                  변경
                </Button>
              )}

              <Button
                type="button"
                color="secondary"
                onClick={() => onCloseModaldelete('VaryingMdo')}
              >
                닫기
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </Fragment>
  )
}

export default Userlist
