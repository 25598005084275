import React, { Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb'


import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'

const ListPages = () => {
  return (
    <Fragment>
      <Breadcrumb title="List Pages" parent="Pages" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Products Category</h5>
              </CardHeader>
              <CardBody>
                <div
                  id="batchDelete"
                  className="category-table order-table coupon-list-delete"
                ></div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default ListPages
