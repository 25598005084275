import React, { Fragment, useState, useRef } from 'react'

import { useQuery, useMutation } from '@apollo/client'
import { gql } from '@apollo/client'



import {
  Card,
  CardBody,

  Col,

  Row,

  Button,

  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
 
  CardTitle,

} from 'reactstrap'

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'

const CREATE_CATEGORY = gql`
  mutation cretecategory($name: String!) {
    cretecategory(name: $name) {
      id
    }
  }
`

const DELETE_CATEGORY = gql`
  mutation deletecategory($id: Int!) {
    deletecategory(id: $id) {
      id
    }
  }
`

const GET_CATEGORY = gql`
  query categories {
    categories {
      id
      name
    }
  }
`

const Create_category = () => {
  var node = useRef()

  const [open, setOpen] = useState(false)
  const [opendelete, setOpendelete] = useState(false)


  const [formState, setFormState] = useState({
    itemname: '',
    itemprice: '',
    orderdata: [],
    users: '',
    groupname: 'A',
    id: '',
    time: '9시',
    status: '',
    date: '',
    reservationdetail: '',
  })


 

  var { loading, data } = useQuery(GET_CATEGORY, {
    onCompleted: (data) => {

      setFormState({
        ...formState,
        orderdata: data.categories,
      })
    },
    onError: (error) => {
      return window.alert("오류 발생")
    },
  })
  const onClickDelete = (user) => {

    setFormState({
      ...formState,
      id: user.id,
      name: user.name,
    })
    setOpendelete(true)
  }

  const contactListColumns = [
    {
      text: '카테고리 이름',
      dataField: 'name',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
    },

    {
      text: '삭제',
      dataField: 'id',
      sort: true,
      headerStyle: {
        backgroundColor: '#f8f9fa',
      },
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          <Button
            color="primary"
            type="button"
            onClick={() => onClickDelete(user)}
          >
            삭제하기
          </Button>
        </div>
      ),
    },
  ]

  const startgroupedit = (data) => {
    setOpen(true)
  }

  const onCloseModal = () => {
    setOpen(false)
  }
  const onCloseModaldelete = () => {
    setOpendelete(false)
  }

  const handleChange = (event) => {
    // setitemname(event.target.value);
    setFormState({
      ...formState,
      itemname: event.target.value,
    })
  }

  const [createCategory, { data2, error2 }] = useMutation(CREATE_CATEGORY, {
    onCompleted: (data2) => {
      window.alert('업데이트 완료')
      window.location.reload()
    },
    onError: (error2) => {
      return window.alert("오류 발생")
    }
  })

  const [deletedatabyid, { data223, error223 }] = useMutation(DELETE_CATEGORY, {
    onCompleted: (data223) => {
 
      window.alert('삭제 완료')
      window.location.reload()
    },
    onError: (error223) => {
      return window.alert("오류 발생")
    }
  })

  const startmutation = () => {
    createCategory({
      variables: {
        name: String(formState.itemname),
      },
    })
  }

  const startdeletebyidmutation = () => {

    deletedatabyid({
      variables: {
        id: Number(formState.id),
      },
    })
  }



  return (
    <Fragment>
      <Card>
        <CardBody>
          <CardTitle className="h4">카테고리 생성</CardTitle>
          <p className="card-title-desc">
            상품 카테고리 추가 및 초기화 할 수 있습니다.
          </p>

          <>
            <Row>
              <Col sm="12">
                {
                  <PaginationProvider
                    pagination={paginationFactory({
                      sizePerPage: 500,
                      totalSize: formState.orderdata?.length, // replace later with size(users),
                      custom: true,
                    })}
                    keyField={'id'}
                    columns={contactListColumns}
                    data={formState.orderdata}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField={'id'}
                          data={formState.orderdata}
                          columns={contactListColumns}
                          bootstrap4
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8"></Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={'id'}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={[
                                        {
                                          dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
                                          order: 'desc', // desc or asc
                                        },
                                      ]}
                                      classes={
                                        'table align-middle table-bordered table-hover text-centered'
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                              ,
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                }
                <Button onClick={() => startgroupedit()}>카테고리 생성</Button>
              </Col>
            </Row>
          </>
        </CardBody>
      </Card>

      <>
        {formState && (
          <>
            <Modal
              isOpen={opendelete}
              toggle={onCloseModaldelete}
              style={{ overlay: { opacity: 0.1 } }}
            >
              <ModalBody>
                <Label htmlFor="recipient-name" className="col-form-label">
                  {formState.name} 을(를) 삭제하시겠습니까?
                </Label>

                {/* <Input type="text"  readOnly  className="form-control" /> */}
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => startdeletebyidmutation()}
                >
                  삭제
                </Button>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => onCloseModaldelete('VaryingMdo')}
                >
                  닫기
                </Button>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={open}
              toggle={onCloseModal}
              style={{ overlay: { opacity: 0.1 } }}
            >
              <ModalHeader toggle={onCloseModal}>
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                  카테고리 생성
                </h5>
              </ModalHeader>
              <ModalBody>
                <Label htmlFor="recipient-name" className="col-form-label">
                  카테고리 이름 :
                </Label>
                <Input
                  className="touchspin form-control"
                  type="text"
                  value={formState.itemname}
                  onChange={handleChange}
                />

                {/* <Input type="text"  readOnly  className="form-control" /> */}
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => startmutation()}
                >
                  저장
                </Button>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => onCloseModal('VaryingMdo')}
                >
                  닫기
                </Button>
              </ModalFooter>
            </Modal>
          </>
        )}
      </>
    </Fragment>
  )
}

// javascript:void(0)

export default Create_category
