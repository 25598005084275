import React, { useEffect, useState, useRef } from 'react'
import { _ } from 'lodash'
import MetaTags from 'react-meta-tags'
import * as AWS from 'aws-sdk'
import Breadcrumb from '../common/breadcrumb'
import { v4 as uuidv4 } from 'uuid'
import { Container, Col, Input, Label, Button, FormGroup } from 'reactstrap'

import { Editor } from '@tinymce/tinymce-react'

import { gql, useMutation, useQuery } from '@apollo/client'

const UPDATE_SELLING = gql`
  mutation createTermsanduse($contents: String) {
    createTermsanduse(contents: $contents) {
      id
    }
  }
`

const ME_QUERY = gql`
  query me {
    me {
      id
      name
      email
    }
  }
`

const GET_CATEGORY = gql`
  query categories {
    categories {
      id
      name
    }
  }
`

const UPDATE_PRODUCT_MUTATION = gql`
  mutation updatesingleproduct(
    $id: Int!
    $title: String
    $description: String
    $type: String
    $brand: String
    $category: String
    $price: Int
    $newproduct: String
    $sale: String
    $stock: String
    $discount: Int
    $variants: String
    $images: String
  ) {
    updatesingleproduct(
      id: $id
      title: $title
      description: $description
      type: $type
      brand: $brand
      category: $category
      price: $price
      newproduct: $newproduct
      sale: $sale
      stock: $stock
      discount: $discount
      variants: $variants
      images: $images
    ) {
      userId
    }
  }
`

const GET_SINGLE_PRODUCTS = gql`
  query product($id: Int!) {
    product(id: $id) {
      createdAt
      id
      title
      description
      type
      brand
      category
      price
      newproduct
      sale
      stock
      discount
      variants
      images
    }
  }
`

const Edit_page = (props) => {
  var node = useRef()


  const [formState, setFormState] = useState({
    id: 0,
    productImages: '',
    subtitle: '',
    descriptionHTMLContent: '',
    title: '',
    description: '',
    type: '',
    brand: '',
    category: '',
    price: 0,
    newproduct: '',
    sale: '',
    stock: '',
    discount: 0,
    variants: '',
    images: '',
    userId: 0,
    productpageId: 0,
    businesscard: '',
  })
  const [imageCenterFormState, setImageCenterFormState] = useState({
    startDate: null,
    endDate: null,
    page: 1,
    size: 20,
    selectedIds: [],
  })

  const { error, data } = useQuery(ME_QUERY, {
    onCompleted: (data) => {
 
      setFormState({
        ...formState,
        id: data.me.id,
      })
    },
    onError: (error) => {
      return window.alert("오류 발생")
    },
  })

  var { data: singleproductdata } = useQuery(GET_SINGLE_PRODUCTS, {
    variables: {
      id: Number(props.match.params.id),
    },
    onCompleted: (singleproductdata) => {
      setFormState({
        ...formState,
        brand: singleproductdata.product.brand,
        category: singleproductdata.product.category,

        createdAt: singleproductdata.product.createdAt,

        subtitle: singleproductdata.product.description,

        discount: singleproductdata.product.discount,

        businesscard: singleproductdata.product.images,

        newproduct: singleproductdata.product.newproduct,

        price: singleproductdata.product.price,

        sale: singleproductdata.product.sale,

        stock: singleproductdata.product.stock,

        title: singleproductdata.product.title,

        type: singleproductdata.product.type,

        variants: singleproductdata.product.variants,
      })
  
    },
  })

  const [createsellingmutation, { data2, error2 }] = useMutation(
    UPDATE_SELLING,
    {
      onCompleted: (data2) => {
        window.alert('업데이트 완료')
        window.location.reload()
      },
      onError: (error2) => {
        return window.alert("오류 발생")
      }
    },
  )

  const [productUpdate] = useMutation(UPDATE_PRODUCT_MUTATION, {
    onError: (error5) => {
 
      window.alert('에러 발생')
    },
    onCompleted: (data5) => {
      window.alert('업데이트 완료')
      window.location.reload()
    },
  })

  const startmutation = () => {
    createsellingmutation({
      variables: {
        contents: formState.descriptionHTMLContent,
      },
    })
  }

  var { data: categoryData } = useQuery(GET_CATEGORY, {
   
    onError: (error) => {
      return window.alert("오류 발생")
    },
  })

  const startcreateproductmutation = () => {

    productUpdate({
      variables: {
        id: Number(props.match.params.id),
        title: String(formState.title),
        description: String(formState.subtitle),
        type: String(formState.type),
        brand: String(formState.brand),
        category: String(formState.category),
        price: Number(formState.price),
        newproduct: 'none',
        sale: String(formState.sale),
        stock: String(formState.stock),
        discount: Number(formState.discount),
        variants: String(formState.variants),
        images: String(formState.businesscard),
      },
    })
  }

  const config = {
    bucketName: process.env.REACT_APP_S3_BUCKETNAME,
    region: process.env.REACT_APP_S3_BUCKETREGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_S3_SECRETACCESSKEY,
  }

  const s3 = new AWS.S3({
    region: process.env.REACT_APP_S3_BUCKETNAME,
    accessKeyId: process.env.REACT_APP_S3_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_S3_SECRETACCESSKEY,
  })

  const uploadToS3 = async (data) => {
    //   let name1 = uuidv4() + data.type;
    let name = uuidv4() + '.' + data.type.substring(6)

    await s3
      .putObject({
        Key: name,
        Bucket: 'yoyomoadmin',
        ContentType: data.type,
        Body: data,
      })
      .promise()
    return `https://${config.bucketName}.s3.${config.region}.amazonaws.com/${name}`
  }

  const uploadFileMain = async (e) => {
    try {
      if (e.target.files.length > 0) {
        for (let i = 0; i < e.target.files.length; i++) {
          // uploadFile1(s3images1[i], s3images1[i]);
          const url = await uploadToS3(e.target.files[i])

          if (e.target.files.length - 1 == i) {
            setFormState({
              ...formState,
              businesscard: url,
            })
            return
          }
        }
      } else {
        const url = await uploadToS3(e.target.files[0])
      }
    } catch (error) {
      window.alert(
        '업로드 도중 오류가 발생하였습니다. 잠시 후 다시 시도 부탁드립니다.',
        error,
      )
    }
  }

  const uploadFile = async (e) => {
    try {
      if (e.target.files.length > 0) {
        for (let i = 0; i < e.target.files.length; i++) {
          // uploadFile1(s3images1[i], s3images1[i]);
          const url = await uploadToS3(e.target.files[i])

          if (e.target.files.length - 1 == i) {
            setFormState({
              ...formState,
              type: url,
            })
            return
          }
        }
      } else {
        const url = await uploadToS3(e.target.files[0])
      }
    } catch (error) {
      window.alert(
        '업로드 도중 오류가 발생하였습니다. 잠시 후 다시 시도 부탁드립니다.',
        error,
      )
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>관리자페이지 </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="제품 수정" parent="Pages" />
          <Col xs={12}>
            <FormGroup className="row">
              <Label className="col-xl-3 col-md-4">
                <span style={{color:"red"}}>*</span> 제품명
              </Label>
              <Input
                className="form-control col-xl-8 col-md-7"
                type="text"
                required=""
                value={formState.title}
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    title: e.target.value,
                  })
                }}
              />
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-xl-3 col-md-4">
                <span style={{color:"red"}}>*</span> 부제
              </Label>
              <Input
                className="form-control col-xl-8 col-md-7"
                type="text"
                required=""
                value={formState.subtitle}
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    subtitle: e.target.value,
                  })
                }}
              />
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-xl-3 col-md-4">
                <span style={{color:"red"}}>*</span>  점판가<span style={{color:"red"}}>(반드시 숫자만 입력)</span>
              </Label>
              <Input
                className="form-control col-xl-8 col-md-7"
                type="number"
                required=""
                value={formState.sale}
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    sale: e.target.value,
                  })
                }}
              />
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-xl-3 col-md-4">
                <span style={{color:"red"}}>*</span>  비회원 미용실가<span style={{color:"red"}}>(반드시 숫자만 입력)</span>
              </Label>
              <Input
                className="form-control col-xl-8 col-md-7"
                type="number"
                required=""
                value={formState.brand}
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    brand: e.target.value,
                  })
                }}
              />
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-xl-3 col-md-4">
                <span style={{color:"red"}}>*</span>  공동제조 후 멤버구입가<span style={{color:"red"}}>(반드시 숫자만 입력)</span>
              </Label>
              <Input
                className="form-control col-xl-8 col-md-7"
                type="number"
                required=""
                value={formState.discount}
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    discount: e.target.value,
                  })
                }}
              />
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-xl-3 col-md-4">
                <span style={{color:"red"}}>*</span>  공동제조 참여가<span style={{color:"red"}}>(반드시 숫자만 입력)</span>
                <strong>
                  {' '}
                  <br />
                  상시판매 제품일 경우, <br />
                  여기에 최종 판매가 기재
                </strong>
              </Label>
              <Input
                className="form-control col-xl-8 col-md-7"
                type="number"
                required=""
                value={formState.stock}
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    stock: e.target.value,
                  })
                }}
              />
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-xl-3 col-md-4">
                <span style={{color:"red"}}>*</span>  요포인트<span style={{color:"red"}}>(반드시 숫자만 입력)</span>
              </Label>
              <Input
                className="form-control col-xl-8 col-md-7"
                type="number"
                required=""
                value={formState.price}
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    price: e.target.value,
                  })
                }}
              />
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-xl-3 col-md-4">
                <span style={{color:"red"}}>*</span>  펀딩안내 문구
              </Label>
              <Input
                className="form-control col-xl-8 col-md-7"
                type="text"
                required=""
                placeholder="펀딩은 2022년 11월 30일 종료되며, 예상 배송일자는 12월 15일 - 18일 경입니다."
                value={formState.variants}
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    variants: e.target.value,
                  })
                }}
              />
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-xl-3 col-md-4">
                <span style={{color:"red"}}>*</span>  카테고리
              </Label>

              <div className="field-label col-xl-8 col-md-7">
                <select
                  value={formState.category}
                  defaultValue={formState.category}
                  className="form-control"
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      category: e.target.value,
                    })
                  }}
                >
                  {categoryData?.categories?.map((data, key) => (
                    <option value={data.name} key={key}>
                      {data.name}
                    </option>
                  ))}
                </select>
              </div>
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-xl-3 col-md-4">
                <span style={{color:"red"}}>*</span>  상품 썸네일 업로드
                <br />
                (업로드 완료 후 기존 사진 삭제)
              </Label>

              <Input
                className="form-control form-control-md"
                id="formFileLg"
                type="file"
                name="file"
                multiple
                onChange={uploadFileMain}
                ref={node}
              />
              {formState.businesscard?.length > 0 && (
                <div className="form-button">
                  <a
                    style={{
                      marginTop: '10px',
                      marginBottom: '40px',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                    color="primary"
                    href={formState.businesscard}
                    target="_blank"
                  >
                    상품 썸네일 미리보기
                  </a>
                </div>
              )}
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-xl-3 col-md-4">
                <span style={{color:"red"}}>*</span>  상품 상세페이지 업로드
                <br />
                (업로드 완료 후 기존 사진 삭제)
              </Label>

              <Input
                className="form-control form-control-md"
                id="formFileLg"
                type="file"
                name="file"
                multiple
                onChange={uploadFile}
                ref={node}
              />
              {formState.type?.length > 0 && (
                <div className="form-button">
                  <a
                    style={{
                      marginTop: '10px',
                      marginBottom: '40px',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                    color="primary"
                    href={formState.type}
                    target="_blank"
                  >
                    상품 상세페이지 미리보기
                  </a>
                </div>
              )}
            </FormGroup>
            <Button onClick={() => startcreateproductmutation()}>
              상품 수정
            </Button>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Edit_page
